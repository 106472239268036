import {BorderType} from '@PosterWhiteboard/classes/item-border.class';
import type {RGBA} from '@Utils/color.util';

interface PMWShapeToRectangleMap {
  fillColor: RGBA;
  cornerRoundness: number;
  borderThickness: number;
  borderType: BorderType;
  borderColor: RGBA;
  width: number;
  height: number;
  rotation?: number;
}

export const PMW_SHAPE_TO_RECTANGLE_HASHMAP: Record<string, PMWShapeToRectangleMap> = {
  '803': {
    fillColor: [248, 176, 181, 1],
    cornerRoundness: 0,
    borderType: BorderType.RECTANGLE_BORDER,
    borderThickness: 2,
    borderColor: [219, 143, 149, 1],
    width: 300,
    height: 300,
  },
  '804': {
    fillColor: [245, 183, 206, 1],
    cornerRoundness: 0,
    borderType: BorderType.RECTANGLE_BORDER,
    borderThickness: 0,
    borderColor: [166, 89, 118, 1],
    width: 180,
    height: 8,
  },
  '941': {
    fillColor: [211, 237, 237, 1],
    cornerRoundness: 60,
    borderType: BorderType.RECTANGLE_BORDER,
    borderThickness: 2,
    borderColor: [152, 192, 192, 1],
    width: 300,
    height: 300,
  },
  '945': {
    fillColor: [245, 183, 206, 1],
    cornerRoundness: 0,
    borderType: BorderType.RECTANGLE_BORDER,
    borderThickness: 1,
    borderColor: [151, 68, 99, 1],
    width: 180,
    height: 110,
  },
  '970': {
    fillColor: [232, 204, 237, 1],
    cornerRoundness: 20,
    borderType: BorderType.RECTANGLE_BORDER,
    borderThickness: 1,
    borderColor: [205, 164, 212, 1],
    width: 360,
    height: 130,
  },
  '971': {
    fillColor: [245, 183, 206, 1],
    cornerRoundness: 20,
    borderType: BorderType.RECTANGLE_BORDER,
    borderThickness: 2,
    borderColor: [151, 68, 99, 1],
    width: 300,
    height: 300,
    rotation: 45,
  },
  '973': {
    fillColor: [131, 200, 130, 1],
    cornerRoundness: 30,
    borderType: BorderType.RECTANGLE_BORDER,
    borderThickness: 2,
    borderColor: [70, 145, 69, 1],
    width: 300,
    height: 300,
  },
  '991': {
    fillColor: [255, 219, 184, 0],
    cornerRoundness: 0,
    borderType: BorderType.RECTANGLE_BORDER,
    borderThickness: 9,
    borderColor: [213, 165, 119, 1],
    width: 300,
    height: 300,
  },
  '1061': {
    fillColor: [254, 217, 181, 1],
    cornerRoundness: 8,
    borderType: BorderType.RECTANGLE_BORDER,
    borderThickness: 2,
    borderColor: [213, 165, 119, 1],
    width: 500,
    height: 300,
  },
};
