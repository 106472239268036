import type {Page} from '@PosterWhiteboard/page/page.class';
import {v4 as uuidv4} from 'uuid';
import {ITEM_CONTROL_DIMENSIONS, ItemBorderColor} from '@PosterWhiteboard/poster/poster-item-controls';
import {CommonMethods} from '@PosterWhiteboard/common-methods';
import {ItemBorder} from '@PosterWhiteboard/classes/item-border.class';
import {degreesToRadians, doPolygonsIntersect} from '@Utils/math.util';
import type {ImageItem} from '@PosterWhiteboard/items/image-item/image-item.class';
import type {TableItem} from '@PosterWhiteboard/items/table-item/table-item.class';
import type {UpdateFromObjectOpts} from '@PosterWhiteboard/common.types';
import {NUM_FRACTION_DIGITS} from '@PosterWhiteboard/common.types';
import type {MenuItem} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import type {TextItem} from '@PosterWhiteboard/items/text-item/text-item.class';
import type {TextSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/text-slide-item.class';
import type {ImageSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/image-slide-item.class';
import type {VideoSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/video-slide-item.class';
import type {SlideshowItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import type {FancyTextItem} from '@PosterWhiteboard/items/fancy-text-item/fancy-text-item.class';
import type {TabsItem} from '@PosterWhiteboard/items/tabs-item/tabs-item.class';
import type {VideoItem} from '@PosterWhiteboard/items/video-item/video-item.class';
import type {RGBA} from '@Utils/color.util';
import type {VectorItem} from '@PosterWhiteboard/items/vector-item/vector-item.class';
import type {StickerItem} from '@PosterWhiteboard/items/sticker-item.class';
import type {QRCodeItem} from '@PosterWhiteboard/items/qr-code-item.class';
import {AuraType, ItemAura} from '@PosterWhiteboard/classes/item-aura.class';
import {LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import {cloneAsAlignedImage} from '@Utils/fabric.util';
import {updateSidebarState} from '@Components/poster-editor/poster-editor-reducer';
import {ItemLoading} from '@PosterWhiteboard/items/item/item-loading.class';
import type {FabricObject, Shadow} from '@postermywall/fabricjs-2';
import {ActiveSelection, util} from '@postermywall/fabricjs-2';
import type {TranscriptItem} from '@PosterWhiteboard/items/transcript-item/transcript-item';
import {POSTER_VERSION} from '@PosterWhiteboard/poster/poster.types';
import type {RectangleItem} from '@PosterWhiteboard/items/rectangle-item/rectangle-item';
import type {LineItem} from '@PosterWhiteboard/items/line-item/line-item';
import type {ImageBackgroundItem} from '@PosterWhiteboard/page/background/image-background-item.class';
import type {
  CommonProperties,
  CopyableItemStylesAndProperties,
} from '@Components/poster-editor/components/poster-editing-side-panel/components/poster-item-controls/poster-item-controls.types';
import type {BaseItemObject, InitItemOpts, ItemObject, ItemsWithFonts} from './item.types';
import {SHADOW_REFERENCE_DIMENSION, ITEM_TYPE} from './item.types';
import type {DeepPartial} from '@/global';
import {round} from 'lodash';

export type ItemFabricObject = FabricObject;

export abstract class Item extends CommonMethods {
  public abstract gitype: ITEM_TYPE;

  public uid: string;
  public page: Page;
  public idOriginalOwner?: number;
  public lockMovement = false;
  public version = 1;
  public clickableLink = '';
  public fabricObject!: FabricObject;
  public border: ItemBorder;
  public loading: ItemLoading;
  public aura: ItemAura;

  private isInitialzed = false;
  private reinitingItem = false;

  public constructor(page: Page) {
    super();
    this.border = new ItemBorder(this);
    this.aura = new ItemAura();
    this.loading = new ItemLoading(this);
    this.page = page;
    this.uid = uuidv4();
  }

  /**
   * Whether this item can safely be converted to svg and inserted in PDF or
   * needs to be rasterized. Defaults to false and will rasterize all items
   * unless overridden by a child class.
   * @returns {Promise<boolean>}
   */
  public async isPDFSafe(): Promise<boolean> {
    return false;
  }

  public async getFabricObjectsForPDF(): Promise<ItemFabricObject[]> {
    const objects = [];
    const isCompatible = await this.isPDFSafe();

    if (!isCompatible) {
      const options = this.isText() || this.isTranscript() || (this.isSlideshow() && this.slides.isFirstSlideNonEmptyText()) ? {expandBoundingBoxByFont: true} : {};
      objects.push(
        await cloneAsAlignedImage(
          this.fabricObject,
          {
            ...options,
            multiplier: this.page.poster.scaling.scale,
          },
          ['__PMWID']
        )
      );
    } else {
      objects.push(this.fabricObject);
    }

    return objects;
  }

  public hasClickableLink(): boolean {
    return !!this.clickableLink;
  }

  public isMotionItem(): this is VideoItem | StickerItem {
    return this.gitype === ITEM_TYPE.VIDEO || this.gitype === ITEM_TYPE.STICKER;
  }

  public isVector(): this is VectorItem {
    return this.gitype === ITEM_TYPE.VECTOR;
  }

  public isText(): this is TextItem {
    return this.gitype === ITEM_TYPE.TEXT;
  }

  public isTranscript(): this is TranscriptItem {
    return this.gitype === ITEM_TYPE.TRANSCRIPT;
  }

  public isQRItem(): this is QRCodeItem {
    return this.gitype === ITEM_TYPE.QR_CODE;
  }

  public canMoveInZIndex(): boolean {
    return true;
  }

  public isVideo(): this is VideoItem {
    return this.gitype === ITEM_TYPE.VIDEO;
  }

  public isImage(): this is ImageItem {
    return this.gitype === ITEM_TYPE.IMAGE;
  }

  public isSticker(): this is StickerItem {
    return this.gitype === ITEM_TYPE.STICKER;
  }

  public isFancyText(): this is FancyTextItem {
    return this.gitype === ITEM_TYPE.FANCY_TEXT;
  }

  public isSlideshow(): this is SlideshowItem {
    return this.gitype === ITEM_TYPE.SLIDESHOW;
  }

  public isTextSlide(): this is TextSlideItem {
    return this.gitype === ITEM_TYPE.TEXTSLIDE;
  }

  public isImageSlide(): this is ImageSlideItem {
    return this.gitype === ITEM_TYPE.IMAGESLIDE;
  }

  public isImageBackground(): this is ImageBackgroundItem {
    return this.gitype === ITEM_TYPE.IMAGEBACKGROUND;
  }

  public isVideoSlide(): this is VideoSlideItem {
    return this.gitype === ITEM_TYPE.VIDEOSLIDE;
  }

  public isRectangle(): this is RectangleItem {
    return this.gitype === ITEM_TYPE.RECTANGLE;
  }

  public isLine(): this is LineItem {
    return this.gitype === ITEM_TYPE.LINE;
  }

  public isSlideshowSlide(): this is SlideshowItem {
    return this.isTextSlide() || this.isMediaSlide();
  }

  public isMediaSlide(): this is ImageSlideItem | VideoSlideItem {
    return this.isImageSlide() || this.isVideoSlide();
  }

  public isMenu(): this is MenuItem {
    return this.gitype === ITEM_TYPE.MENU;
  }

  public isTable(): this is TableItem {
    return this.gitype === ITEM_TYPE.TABLE;
  }

  public isTab(): this is TabsItem {
    return this.gitype === ITEM_TYPE.TAB;
  }

  public isSchedule(): boolean {
    return this.isTable() && this.layoutStyle !== LayoutTypes.CUSTOM_TABLE_LAYOUT;
  }

  public hasEditableText(): this is ItemsWithFonts {
    return (
      this.gitype === ITEM_TYPE.TEXT ||
      this.gitype === ITEM_TYPE.MENU ||
      this.gitype === ITEM_TYPE.TRANSCRIPT ||
      this.gitype === ITEM_TYPE.TAB ||
      this.gitype === ITEM_TYPE.TABLE ||
      this.gitype === ITEM_TYPE.SLIDESHOW
    );
  }

  protected isVisible(): boolean {
    return this.fabricObject.visible;
  }

  public getCopyableStyles(): CopyableItemStylesAndProperties {
    return {
      gitype: this.gitype,
      opacity: this.fabricObject.opacity,
      border: this.border.getCopyableStyles(),
      aura: this.aura.toObject(),
      flipX: this.fabricObject.flipX,
      flipY: this.fabricObject.flipY,
      scaleX: this.fabricObject.scaleX,
      scaleY: this.fabricObject.scaleY,
    } as CommonProperties;
  }

  public updateBoundItemsZIndex(): void {
    this.loading.updateZIndex();
  }

  public async invalidate(reinitItem = false, shouldMaintainScaledDimensionsOnReinit = false): Promise<void> {
    if (reinitItem) {
      await this.reInitFabricObject(shouldMaintainScaledDimensionsOnReinit);
    }

    if (this.fabricObject.group === undefined) {
      await this.updateFabricObject();
    }
    this.loading.invalidate();
    if (!this.page.poster.mode.isGeneration()) {
      this.page.fabricCanvas.requestRenderAll();
    }
  }

  protected updateFabricObjectFromObject(obj: DeepPartial<ItemObject>): void {
    if (obj.x !== undefined || obj.y !== undefined) {
      if (obj.x !== undefined) {
        this.fabricObject.set('left', obj.x);
      }
      if (obj.y !== undefined) {
        this.fabricObject.set('top', obj.y);
      }
      this.fabricObject.setCoords();
    }

    if (obj.alpha !== undefined) {
      this.fabricObject.set('opacity', obj.alpha);
    }

    if (obj.rotation !== undefined) {
      this.fabricObject.set('angle', obj.rotation);
    }

    if (obj.flipX !== undefined) {
      this.fabricObject.set('flipX', obj.flipX);
    }

    if (obj.flipY !== undefined) {
      this.fabricObject.set('flipY', obj.flipY);
    }

    if (obj.visible !== undefined) {
      this.fabricObject.set('visible', obj.visible);
    }

    if (obj.width !== undefined) {
      this.fabricObject.set('width', obj.width);
    }

    if (obj.height !== undefined) {
      this.fabricObject.set('height', obj.height);
    }

    if (obj.scaleX !== undefined) {
      this.fabricObject.set('scaleX', obj.scaleX);
    }

    if (obj.scaleY !== undefined) {
      this.fabricObject.set('scaleY', obj.scaleY);
    }
  }

  public getFonts(withVariation: boolean): string[] {
    return [];
  }

  public async updateFromObject(
    obj: DeepPartial<ItemObject>,
    {
      updateRedux = true,
      applyVersionFixes = false,
      applyVersionFixesData = {},
      undoable = true,
      doInvalidate = true,
      checkForDurationUpdate = false,
      replayPosterOnUpdateDone = false,
    }: UpdateFromObjectOpts = {}
  ): Promise<void> {
    if (this.reinitingItem) {
      return;
    }

    try {
      const additionalOldValuesForReinit = this.getAdditionalOldValuesForReinit();
      const oldItemObject = this.toObject();

      this.copyVals(obj);
      const reinit = this.isInitialzed && this.itemObjectHasDestructiveChanges(oldItemObject, additionalOldValuesForReinit);
      if (reinit) {
        this.reinitingItem = true;
      }

      this.updateFabricObjectFromObject(obj);
      if (applyVersionFixes) {
        this.fixChanges(applyVersionFixesData);
      }

      if (doInvalidate || reinit) {
        await this.invalidate(reinit, this.shouldMaintainScaledDimensionsOnReinit(oldItemObject, additionalOldValuesForReinit));
      }
    } finally {
      if (this.reinitingItem) {
        this.reinitingItem = false;
      }
    }
    await this.onItemUpdatedFromObject();
    if (checkForDurationUpdate) {
      this.checkItemForPageDurationUpdate();
    }

    if (undoable) {
      this.page.poster.history.addPosterHistory();
    }
    if (updateRedux) {
      this.page.poster.redux.updateReduxData();
    }
    if (replayPosterOnUpdateDone) {
      await this.page.poster.replayPoster();
    }
  }

  protected async onItemUpdatedFromObject(): Promise<void> {}

  protected checkItemForPageDurationUpdate(): void {
    if (!this.isChildItem() && this.isStreamingMediaItem()) {
      this.page.calculateAndUpdatePageDuration();
    }
  }

  public override copyVals(obj: DeepPartial<ItemObject>): void {
    const {border, aura, ...plainObj} = obj;
    super.copyVals(plainObj);
    this.border.copyVals(border);
    this.aura.copyVals(aura);
  }

  protected getAdditionalOldValuesForReinit(): Record<string, any> {
    return {};
  }

  public async formatItemObjectWithDefaultValues(obj: DeepPartial<ItemObject>): Promise<DeepPartial<ItemObject>> {
    return {...obj};
  }

  protected itemObjectHasDestructiveChanges(oldItemObject: ItemObject | BaseItemObject, oldAdditionalValues: Record<string, any> = {}): boolean {
    return false;
  }

  protected shouldMaintainScaledDimensionsOnReinit(oldItemObject: ItemObject | BaseItemObject, oldAdditionalValues: Record<string, any> = {}): boolean {
    return true;
  }

  public toObject(): BaseItemObject {
    return {
      uid: this.uid,
      gitype: this.gitype,
      idOriginalOwner: this.idOriginalOwner,
      x: this.getX(),
      y: this.getY(),
      scaleX: this.getScaleX(),
      scaleY: this.getScaleY(),
      alpha: round(this.fabricObject.group instanceof ActiveSelection ? this.fabricObject.getObjectOpacity() : this.fabricObject.opacity, NUM_FRACTION_DIGITS),
      width: round(this.fabricObject.width, NUM_FRACTION_DIGITS),
      height: round(this.fabricObject.height, NUM_FRACTION_DIGITS),
      rotation: round(this.fabricObject.group instanceof ActiveSelection ? this.fabricObject.getTotalAngle() : this.fabricObject.angle, NUM_FRACTION_DIGITS),
      visible: this.fabricObject.visible,
      erasable: this.fabricObject.erasable,
      border: this.border.toObject(),
      aura: this.aura.toObject(),
      clickableLink: this.clickableLink,
      flipX: this.fabricObject.flipX,
      flipY: this.fabricObject.flipY,
      lockMovement: this.lockMovement,
      version: this.version,
    };
  }

  public toDataURL(ignoreAlpha = true): string {
    const opacity = this.fabricObject.opacity;
    if (ignoreAlpha) {
      this.fabricObject.set('opacity', 1);
    }
    const data = this.fabricObject.toDataURL({
      format: 'png',
      quality: 1,
    });
    if (ignoreAlpha) {
      this.fabricObject.set('opacity', opacity);
    }
    return data;
  }

  public isStreamingMediaItem(): boolean {
    return false;
  }

  public isChildItem(): boolean {
    return false;
  }

  /**
   * Returns the duration of item to play
   * This differs from getDuration as this returns not the actual duration but the duration for which the item should be played on poster
   * i.e. for stickers this returns 5 seconds because we wanted stickers to loop for 5 secs
   * @return {number}
   */
  public getPlayDuration(): number {
    return this.getDuration();
  }

  public getDuration(): number {
    return 0;
  }

  public async play(): Promise<void> {
    // override if needed
  }

  public async pause(): Promise<void> {
    // override if needed
  }

  public async stop(): Promise<void> {
    // override if needed
  }

  public async seek(time: number): Promise<void> {
    // override if needed
  }

  public async initFabricObject(opts: InitItemOpts = {}): Promise<void> {
    await this.onBeforeItemInitialize();
    const fabricObject = await this.getFabricObjectForItem(opts);
    this.setFabricObject(fabricObject);
    await this.onItemInitialized();
  }

  protected setFabricObject(fabricObject: ItemFabricObject): void {
    this.fabricObject = fabricObject;
    this.fabricObject.__PMWID = this.uid;
    this.initEvents();
    this.updateItemDimensions();
  }

  protected updateItemDimensions(): void {}

  protected initEvents(): void {
    this.fabricObject.on('modified', this.onFabricObjectModified.bind(this));
    this.fabricObject.on('mousedblclick', this.onDoubleClickItem.bind(this));
  }

  private isSelectedItemTextSlideOrText(): boolean {
    if (!this.page.isSingleItemSelected()) {
      return false;
    }

    const selectedItem = this.page.getSelectedItems()[0];
    return !!(selectedItem.isText() || (selectedItem.isSlideshow() && selectedItem.getSelectedSlide().isTextSlide()));
  }

  private openEditingSidebar(): void {
    if (this.isSelectedItemTextSlideOrText()) {
      return;
    }
    if (window.PMW.redux.store.getState().posterEditor.isSidebarSmall) {
      window.PMW.redux.store.dispatch(updateSidebarState(true));
    }
  }

  private onDoubleClickItem(): void {
    if (this.page.isSingleItemSelected()) {
      this.onItemDoubleClicked();
    }

    this.openEditingSidebar();
  }

  protected onDoubleTapItem(): void {
    this.openEditingSidebar();

    if (!window.PMW.redux.store.getState().posterEditor.isMobileVariant) {
      return;
    }
    if (this.page.isSingleItemSelected() && this.page.activeSelection.getActiveObjects()[0] === this.fabricObject) {
      this.onItemDoubleTapped();
    }
  }

  protected onItemDoubleTapped(): void {}

  protected onItemDoubleClicked(): void {}

  public isPremium(): boolean {
    return false;
  }

  public getBorderColor(): ItemBorderColor {
    return this.isStreamingMediaItem() ? ItemBorderColor.DYNAMIC_ITEM : ItemBorderColor.STATIC_ITEM;
  }

  protected onFabricObjectModified(): void {}

  protected async updateFabricObject(): Promise<void> {
    this.fabricObject.set({
      ...this.getCommonOptions(),
    });

    this.applyBorder();
    // TODO: Call setCoords only when top or left of the item changes. (http://fabricjs.com/fabric-gotchas)
    this.fabricObject.setCoords();
    this.applyShadowToFabricObject();
  }

  protected applyBorder(): void {
    this.fabricObject.set({
      ...this.border.getBorder(),
    });
  }

  protected applyShadowToFabricObject(): void {
    this.fabricObject.set('shadow', this.getShadow());
  }

  protected getScalesToMaintainScaledDimensions(newWidth: number, newHeight: number, opts: InitItemOpts = {}): Record<string, unknown> {
    const fabricOpts: Record<string, unknown> = {};
    if (opts?.width !== undefined && opts?.scaleX !== undefined) {
      const totalDesiredWidth = opts.width * opts.scaleX;
      fabricOpts.scaleX = totalDesiredWidth / newWidth;
    }

    if (opts?.height !== undefined && opts?.scaleY !== undefined) {
      const totalDesiredHeight = opts.height * opts.scaleY;
      fabricOpts.scaleY = totalDesiredHeight / newHeight;
    }
    return fabricOpts;
  }

  protected getCommonOptions(): Record<string, any> {
    return {
      ...this.getCommonOptionsForWebpage(),
      lockMovementX: this.lockMovement,
      lockMovementY: this.lockMovement,
      lockRotation: this.lockMovement,
      lockScalingX: this.lockMovement,
      lockScalingY: this.lockMovement,
      lockSkewingX: true,
      lockSkewingY: true,
      padding: this.getSelectorPadding(),
      hasControls: false,
      hasBorders: false,
    };
  }

  private getCommonOptionsForWebpage(): Record<string, any> {
    if (this.page.poster.mode.isWebpage() || this.page.poster.mode.isGeneration()) {
      return {
        selectable: false,
        hoverCursor: this.isSelectableInWebpage() ? 'pointer' : 'default',
        perPixelTargetFind: true,
      };
    }
    return {};
  }

  protected getInitOptionsForView(): Record<string, any> {
    return {};
  }

  protected async initFabricObjectForReinit(shouldMaintainScaledDimensionsOnReinit = false): Promise<void> {
    const initOpts: InitItemOpts = {};
    const {scaleX, scaleY} = this.fabricObject;
    if (shouldMaintainScaledDimensionsOnReinit) {
      initOpts.width = this.fabricObject.width;
      initOpts.height = this.fabricObject.height;
      initOpts.scaleX = this.fabricObject.scaleX;
      initOpts.scaleY = this.fabricObject.scaleY;
    }

    await this.initFabricObject(initOpts);
    if (!shouldMaintainScaledDimensionsOnReinit) {
      this.fabricObject.set({
        scaleX,
        scaleY,
      });
    }
  }

  protected async reInitFabricObject(shouldMaintainScaledDimensionsOnReinit = false): Promise<void> {
    const wasItemActive = this.isActive();
    const zIndex = this.page.items.getItemOrder(this.uid);
    const oldFabricObject = this.fabricObject;
    await this.beforeInitFabricObject();
    await this.initFabricObjectForReinit(shouldMaintainScaledDimensionsOnReinit);
    this.fabricObject.set({
      top: oldFabricObject.top,
      left: oldFabricObject.left,
      angle: oldFabricObject.angle,
      flipX: oldFabricObject.flipX,
      flipY: oldFabricObject.flipY,
    });
    if (this.page.fabricCanvas.contains(oldFabricObject)) {
      if (zIndex === undefined) {
        this.page.fabricCanvas.add(this.fabricObject);
      } else {
        this.page.fabricCanvas.insertAt(zIndex, this.fabricObject);
      }

      this.page.fabricCanvas.remove(oldFabricObject);
      if (wasItemActive) {
        this.reselectItem(oldFabricObject);
      }
    }
  }

  private reselectItem(oldFabricObject: ItemFabricObject): void {
    const activeObjects = this.page.activeSelection.getActiveObjects();
    const newActiveObjects = [];

    if (!activeObjects) {
      return;
    }

    for (const activeObject of activeObjects) {
      if (activeObject === oldFabricObject) {
        newActiveObjects.push(activeObject);
      } else {
        newActiveObjects.push(activeObject);
      }
    }

    this.page.activeSelection.discardActiveObject();

    if (activeObjects.length > 1) {
      const sel = new ActiveSelection(newActiveObjects, {
        canvas: this.page.fabricCanvas,
      });
      this.page.activeSelection.setActiveObject(sel);
    } else {
      this.page.activeSelection.setActiveObject(this.fabricObject);
    }
  }

  public getSelectorPadding(): number {
    return ITEM_CONTROL_DIMENSIONS.PMW_CONTROL_PADDING;
  }

  protected hasParentGroupGraphicItem(): boolean {
    return !!this.fabricObject.group && !!getFabricObjectUID(this.fabricObject.group);
  }

  protected hasOrderedList(): boolean {
    return false;
  }

  public getParentFabricObject(): ItemFabricObject {
    return this.fabricObject;
  }

  public doesfabricObjBelongtoItem(fabricObj: FabricObject): boolean {
    return this.fabricObject === fabricObj;
  }

  public onMoving(): void {
    this.page.poster.redux.updateReduxItemData(this.page.hashedID, this.uid, {
      x: this.getX(),
      y: this.getY(),
    });
    this.loading.updateLoadingPositionAndRender();
  }

  public onRotating(): void {
    this.page.poster.redux.updateReduxItemData(this.page.hashedID, this.uid, {
      x: this.getX(),
      y: this.getY(),
      rotation: this.getAngle(),
    });
    this.loading.updateLoadingPositionAndRender();
  }

  public onScaling(): void {
    this.page.poster.redux.updateReduxItemData(this.page.hashedID, this.uid, {
      x: this.getX(),
      y: this.getY(),
      scaleX: this.getScaleX(),
      scaleY: this.getScaleY(),
    });
    this.loading.updateTextOnItemResizeAndRender();
  }

  public getAngle(): number {
    return round(this.fabricObject.group instanceof ActiveSelection ? this.fabricObject.getTotalAngle() : this.fabricObject.angle, NUM_FRACTION_DIGITS);
  }

  public getX(): number {
    return round(this.fabricObject.group instanceof ActiveSelection ? this.fabricObject.getX() : this.fabricObject.left, NUM_FRACTION_DIGITS);
  }

  public getY(): number {
    return round(this.fabricObject.group instanceof ActiveSelection ? this.fabricObject.getY() : this.fabricObject.top, NUM_FRACTION_DIGITS);
  }

  public getScaleX(): number {
    return round(this.fabricObject.group instanceof ActiveSelection ? this.fabricObject.getObjectScaling().x : this.fabricObject.scaleX, NUM_FRACTION_DIGITS);
  }

  public getScaleY(): number {
    return round(this.fabricObject.group instanceof ActiveSelection ? this.fabricObject.getObjectScaling().y : this.fabricObject.scaleY, NUM_FRACTION_DIGITS);
  }

  protected abstract getFabricObjectForItem(opts?: InitItemOpts): Promise<FabricObject>;

  public getScaledHeight(): number {
    return this.fabricObject.getObjectScaling().y * this.fabricObject.height;
  }

  public getScaledWidth(): number {
    return this.fabricObject.getObjectScaling().x * this.fabricObject.width;
  }

  public getZIndex(): number {
    return this.page.fabricCanvas.getObjects().indexOf(this.fabricObject);
  }

  public isLocked(): boolean {
    return this.lockMovement;
  }

  public isActive(): boolean {
    const parentItemPmwId = this.getParentFabricObject().__PMWID;
    const activeObjects = this.page.activeSelection.getActiveObjects();
    if (activeObjects) {
      for (const activeObject of activeObjects) {
        if (activeObject.__PMWID === parentItemPmwId) {
          return true;
        }
      }
    }
    return false;
  }

  public async init(opts: DeepPartial<ItemObject>, doInvalidate = true): Promise<void> {
    if (!this.isInitialzed) {
      await this.beforeInitFabricObject();
      const {width, height, scaleX, scaleY, ...itemObj} = opts;
      this.copyVals(itemObj);
      await this.initFabricObject({
        width,
        height,
        scaleX,
        scaleY,
      });
      await this.updateFromObject(itemObj, {
        updateRedux: false,
        undoable: false,
        applyVersionFixes: true,
        applyVersionFixesData: {
          width,
          height,
          scaleX,
          scaleY,
        },
        doInvalidate,
      });
      this.isInitialzed = true;
    }
  }

  protected fixChanges(applyVersionFixesData: Record<string, any>): void {
    this.fixAura();
  }

  protected fixAura(): void {
    if (this.page.poster.version < POSTER_VERSION.SHADOW_IMPROVEMENT) {
      if (this.aura.isShadow()) {
        let newShadowDistance;
        const shadowScale = this.getScaleForShadow();

        if (this.aura.isCustomShadow()) {
          newShadowDistance = Math.round(
            ((this.getOldShadowDistance() + 2) * Math.cos(degreesToRadians(this.aura.dropShadowAngle))) / (shadowScale * Math.cos(degreesToRadians(this.aura.dropShadowAngle)))
          );
        } else {
          this.aura.dropShadowColor = [0, 0, 0, 1];
          this.aura.dropShadowColor[3] = this.aura.type === AuraType.LIGHT_SHADOW ? 0.25 : 0.5;
          this.aura.dropShadowAngle = 45;
          newShadowDistance = Math.round(this.getOldShadowDistance() / (shadowScale * Math.cos(degreesToRadians(this.aura.dropShadowAngle))));
        }
        this.aura.dropShadowDistance = newShadowDistance;
        this.aura.dropShadowBlur = Math.round(this.getOldShadowBlur() / shadowScale);
      }
    }
  }

  protected getScaleForShadow(): number {
    const maxDimension = Math.max(this.fabricObject.height, this.fabricObject.width);
    return maxDimension / SHADOW_REFERENCE_DIMENSION;
  }

  protected getShadow(): Shadow | null {
    return this.aura.getItemAura(this.getScaleForShadow());
  }

  protected getOldShadowBlur(): number {
    return 2;
  }

  protected getOldShadowDistance(): number {
    return 6;
  }

  protected async beforeInitFabricObject(): Promise<void> {}

  public async onItemAddedToPage(): Promise<void> {
    // override if needed
  }

  protected async onItemInitialized(): Promise<void> {
    // override if needed
  }

  protected async onBeforeItemInitialize(): Promise<void> {
    // override if needed
  }

  public onRemove(): void {
    this.loading.removeLoading();
  }

  public async stretchToCanvasHorizontally(leaveSpace = false): Promise<void> {
    const cornerPoints = this.fabricObject.getCoords();
    const tlCorner = cornerPoints[0];
    let offset = 0;

    if (leaveSpace) {
      offset = this.page.poster.width * 0.2;
    }
    const newScaleX = ((this.page.poster.width - offset) * this.fabricObject.scaleX) / this.getBoundingBoxWidth();
    const newScaleY = (newScaleX / this.fabricObject.scaleX) * this.fabricObject.scaleY;

    // The left and top property of vo doesn't represent the tl corner appearing on the canvas if the graphic item is rotated. So we first find out the corner which has the least value of x and make it 0 so that
    // the whole graphic item remains on the canvas. After finding out the left most corner we set the left property of vo such that this corner gets x-axis value as 0
    let leftMostCornerPointIndex = 0;
    let newTlCornerXCoordinate = -this.getManualSelectorPadding();

    for (let i = 0; i < cornerPoints.length; i++) {
      if (cornerPoints[i].x < cornerPoints[leftMostCornerPointIndex].x) {
        leftMostCornerPointIndex = i;
      }
    }

    if (leftMostCornerPointIndex !== 0) {
      const selectorPaddingOffset = this.getManualSelectorPadding() * 2;
      newTlCornerXCoordinate = ((tlCorner.x - cornerPoints[leftMostCornerPointIndex].x - selectorPaddingOffset) * newScaleX) / this.fabricObject.scaleX;
    }

    await this.updateFromObject({
      x: newTlCornerXCoordinate,
      scaleX: newScaleX,
      scaleY: newScaleY,
    });
  }

  public async stretchToCanvasVertically(leaveSpace = false): Promise<void> {
    const cornerPoints = this.fabricObject.getCoords();
    const tlCorner = cornerPoints[0];
    let offset = 0;

    if (leaveSpace) {
      offset = this.page.poster.height * 0.2;
    }
    const newScaleY = ((this.page.poster.height - offset) * this.fabricObject.scaleY) / this.getBoundingBoxHeight();
    const newScaleX = (newScaleY / this.fabricObject.scaleY) * this.fabricObject.scaleX;

    // The left and top property of vo doesn't represent the tl corner appearing on the canvas if the graphic item is rotated. So we first find out the corner which has the least value of y and make it 0 so that
    // the whole graphic item remains on the canvas. After finding out the top most corner we set the top property of vo such that this corner gets y-axis value as 0
    let topMostCornerPoint = 0;
    let newTlCornerYCoordinate = -this.getManualSelectorPadding();

    for (let j = 0; j < cornerPoints.length; j++) {
      if (cornerPoints[j].y < cornerPoints[topMostCornerPoint].y) {
        topMostCornerPoint = j;
      }
    }

    if (topMostCornerPoint !== 0) {
      const selectorPaddingOffset = this.getManualSelectorPadding() * 2;
      newTlCornerYCoordinate = ((tlCorner.y - cornerPoints[topMostCornerPoint].y - selectorPaddingOffset) * newScaleY) / this.fabricObject.scaleY;
    }

    await this.updateFromObject({
      y: newTlCornerYCoordinate,
      scaleX: newScaleX,
      scaleY: newScaleY,
    });
  }

  public getBoundingBoxWidth(): number {
    const cornerPoints = this.fabricObject.getCoords();
    let leftMostCornerPointIndex = 0;
    let rightMostCornerPointIndex = 0;

    for (let i = 0; i < cornerPoints.length; i++) {
      if (cornerPoints[i].x < cornerPoints[leftMostCornerPointIndex].x) {
        leftMostCornerPointIndex = i;
      }
      if (cornerPoints[i].x > cornerPoints[rightMostCornerPointIndex].x) {
        rightMostCornerPointIndex = i;
      }
    }
    return cornerPoints[rightMostCornerPointIndex].x - cornerPoints[leftMostCornerPointIndex].x;
  }

  public getBoundingBoxHeight(): number {
    const cornerPoints = this.fabricObject.getCoords();
    let topMostCornerPointIndex = 0;
    let bottomMostCornerPointIndex = 0;

    for (let i = 0; i < cornerPoints.length; i++) {
      if (cornerPoints[i].y < cornerPoints[topMostCornerPointIndex].y) {
        topMostCornerPointIndex = i;
      }
      if (cornerPoints[i].y > cornerPoints[bottomMostCornerPointIndex].y) {
        bottomMostCornerPointIndex = i;
      }
    }
    return cornerPoints[bottomMostCornerPointIndex].y - cornerPoints[topMostCornerPointIndex].y;
  }

  /**
   * Returns the manually added selector padding.
   */
  public getManualSelectorPadding(): number {
    return 0;
  }

  public hasEditMode(): boolean {
    return false;
  }

  public getColors(): RGBA[] {
    const colors: RGBA[] = [];
    if (this.border.hasBorder()) {
      colors.push(this.border.solidBorderColor);
    }

    if (this.aura.isCustomShadow()) {
      colors.push(this.aura.dropShadowColor);
    }

    return colors;
  }

  public isItemVisibleOnPoster(): boolean {
    return (
      this.isVisible() && this.fabricObject.width > 0 && this.fabricObject.height > 0 && doPolygonsIntersect(this.fabricObject.getCoords(), this.page.poster.getCornerPointsArray())
    );
  }

  private isSelectableInWebpage = (): boolean => {
    return this.isSlideshow() || this.hasClickableLink();
  };

  public isOutlineDisabled(): boolean {
    if ((this.isText() || this.isTextSlide() || this.isTab()) && this.textStyles.isBold) {
      return true;
    }

    return !!((this.isMenu() || this.isTable()) && (this.isBold2 || this.textStyles.isBold));
  }

  public updateClickableLink(message: string, undoable = true): void {
    void this.updateFromObject(
      {
        clickableLink: message,
      },
      {undoable}
    );
  }

  public flipItemVertically(): void {
    void this.updateFromObject({
      flipY: !this.fabricObject.get('flipY'),
    });
  }

  public flipItemHorizontally(): void {
    void this.updateFromObject({
      flipX: !this.fabricObject.get('flipX'),
    });
  }

  public getFabricObjectAbsoluteLeft(): number {
    if (this.fabricObject.group) {
      return util.transformPoint({x: -this.fabricObject.width / 2, y: -this.fabricObject.height / 2}, this.fabricObject.calcTransformMatrix()).x;
    }

    return this.fabricObject.left;
  }

  public getFabricObjectAbsoluteTop(): number {
    if (this.fabricObject.group) {
      return util.transformPoint({x: -this.fabricObject.width / 2, y: -this.fabricObject.height / 2}, this.fabricObject.calcTransformMatrix()).y;
    }

    return this.fabricObject.top;
  }

  public getFabricObjectAbsoluteAngle(): number {
    if (this.fabricObject.group) {
      return util.qrDecompose(this.fabricObject.calcTransformMatrix()).angle;
    }

    return this.fabricObject.angle;
  }

  public getFabricObjectAbsoluteScaledWidth(): number {
    if (this.fabricObject.group) {
      return util.qrDecompose(this.fabricObject.calcTransformMatrix()).scaleX * this.fabricObject.width;
    }

    return this.fabricObject.getScaledWidth();
  }

  public getFabricObjectAbsoluteScaledHeight(): number {
    if (this.fabricObject.group) {
      return util.qrDecompose(this.fabricObject.calcTransformMatrix()).scaleY * this.fabricObject.height;
    }

    return this.fabricObject.getScaledHeight();
  }
}

export const getFabricObjectUID = (view: FabricObject): string => {
  return view.__PMWID;
};
