import {
  addFonts,
  FONT_LOAD_STATUS,
  FONT_VARIATION,
  fontsRequestedMap,
  getFontFamilyNameForVariations,
  getFontFamilyString,
  isBoldVariationAvaliableForFont,
  isItalicVariationAvaliableForFont,
  getScriptForText,
  getRecommendedFont,
  isInternalFont,
} from '@Libraries/font-library';
import {validateLineHeight} from '@PosterWhiteboard/libraries/text.library';
import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {RGBA} from '@Utils/color.util';
import {hexToRgba} from '@Utils/color.util';
import {getRgbLuminance, lightenDarkenColor} from '@Utils/color.util';
import type * as Fabric from '@postermywall/fabricjs-2';
import type {FillObject} from '@PosterWhiteboard/classes/fill.class';
import {Fill} from '@PosterWhiteboard/classes/fill.class';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {DeepPartial} from '@/global';

export enum TextVerticalAlignType {
  CENTER = 'center',
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum TextHorizontalAlignType {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify',
}

export const DEFAULT_TEXT_FONT_FAMILY = 'RalewayRegular';
export const DEFAULT_FONT_STYLE = 'normal';
export const DEFAULT_FONT_WEIGHT = 'normal';
export const DEFAULT_STROKE_WIDTH = 1;
export const MIN_STROKE_WIDTH = 0;
export const MAX_STROKE_WIDTH = 1;
export const TEXT_OUTLINE_STROKE_WIDTH_FACTOR = 0.2;
export const BOLD_STROKE_WIDTH_FACTOR = 0.05;

export interface TextStylesObject {
  fill: FillObject;
  fontSize: number;
  fontFamily: string;
  fontStyle: string;
  fontWeight: string;
  letterSpacing: number;
  fontLicense: string;
  leading: number;
  textAlign: TextHorizontalAlignType;
  script: string;
  isBold: boolean;
  isItalic: boolean;
  underLine: boolean;
  lineThrough: boolean;
  stroke: boolean;
  strokeColor: RGBA;
  strokeWidth: number;
}

export interface FabricTextItemStyles {
  fontSize: number;
  fontFamily: string;
  fontWeight: string;
  textAlign: TextHorizontalAlignType;
  charSpacing: number;
  linethrough: boolean;
  underline: boolean;
  lineHeight: number;
  fontStyle: string;
  fill: Fabric.TFiller | string;
  stroke: Fabric.TFiller | string | null;
  strokeLineJoin: CanvasLineJoin;
  strokeWidth: number;
}

export class TextStyles extends CommonMethods {
  public fill: Fill;
  public fontSize = 0;
  public fontFamily = DEFAULT_TEXT_FONT_FAMILY;
  public fontStyle = DEFAULT_FONT_STYLE;
  public fontWeight = DEFAULT_FONT_WEIGHT;
  public letterSpacing = 0;
  public fontLicense = '';
  public editable = true;
  public leading = 120;
  public textAlign: TextHorizontalAlignType = TextHorizontalAlignType.LEFT;
  public script = '';
  public isBold = false;
  public isItalic = false;
  public underLine = false;
  public lineThrough = false;
  public stroke = false;
  public strokeColor: RGBA = [0, 0, 0, 1];
  public strokeWidth = DEFAULT_STROKE_WIDTH;

  constructor() {
    super();
    this.fill = new Fill();
  }

  copyVals(obj: DeepPartial<TextStylesObject> = {}): void {
    const {fill, ...itemObj} = obj;
    super.copyVals(itemObj);
    this.fill.copyVals(fill);
  }

  public toObject(): TextStylesObject {
    return {
      fill: this.fill.toObject(),
      fontSize: this.fontSize,
      fontFamily: this.fontFamily,
      fontStyle: this.fontStyle,
      fontWeight: this.fontWeight,
      letterSpacing: this.letterSpacing,
      fontLicense: this.fontLicense,
      leading: this.leading,
      textAlign: this.textAlign,
      script: this.script,
      isBold: this.isBold,
      isItalic: this.isItalic,
      underLine: this.underLine,
      lineThrough: this.lineThrough,
      stroke: this.stroke,
      strokeColor: this.strokeColor,
      strokeWidth: this.strokeWidth,
    };
  }

  public getTextStyles(fillWidth: number, fillHeight: number): FabricTextItemStyles {
    const fontFamilyWithVariation = this.getFontFamilyToLoad();
    const isFontLoaded = fontsRequestedMap[fontFamilyWithVariation] === (FONT_LOAD_STATUS.LOADED as number);
    const isBoldApplied = isFontLoaded ? isBoldVariationAvaliableForFont(this.fontFamily) : false;
    const isItalicApplied = isFontLoaded ? isItalicVariationAvaliableForFont(this.fontFamily) : false;

    const styles: FabricTextItemStyles = {
      fontSize: this.fontSize,
      fontFamily: getFontFamilyString(this.getFontFamilyToLoad()),
      fontWeight: this.fontWeight,
      // should be getting normal instead of italic for menu
      fontStyle: this.isItalic && !isItalicApplied ? FONT_VARIATION.ITALIC : DEFAULT_FONT_STYLE,
      // fontStyle: DEFAULT_FONT_STYLE,
      textAlign: this.textAlign,
      fill: this.fill.getFill(fillWidth, fillHeight),
      charSpacing: this.letterSpacing,
      linethrough: this.lineThrough,
      underline: this.underLine,
      lineHeight: validateLineHeight(this.leading),
      stroke: null,
      strokeLineJoin: 'miter',
      strokeWidth: DEFAULT_STROKE_WIDTH,
    };

    if (this.isBold && !isBoldApplied) {
      return {
        ...styles,
        stroke: this.fill.getFill(fillWidth, fillHeight),
        strokeLineJoin: 'round',
        strokeWidth: this.getBoldStrokeWidth(),
      };
    }

    return styles;
  }

  public getFontStyle(): string {
    const fontFamilyWithVariation = this.getFontFamilyToLoad();
    const isFontLoaded = fontsRequestedMap[fontFamilyWithVariation] === (FONT_LOAD_STATUS.LOADED as number);
    const isItalicApplied = isFontLoaded ? isItalicVariationAvaliableForFont(this.fontFamily) : false;

    return this.isItalic && !isItalicApplied ? FONT_VARIATION.ITALIC : DEFAULT_FONT_STYLE;
  }

  public getLoadedTextStyles(fillWidth: number, fillHeight: number): Promise<Partial<FabricTextItemStyles>> {
    const styles = this.getTextStyles(fillWidth, fillHeight);
    return new Promise((resolve, reject) => {
      addFonts(
        [this.getFontFamilyToLoad()],
        () => {
          resolve(styles);
        },
        reject
      );
    });
  }

  public getFontFamilyToLoad(): string {
    return getFontFamilyNameForVariations(this.fontFamily, this.isBold, this.isItalic);
  }

  private getBoldStrokeWidth(): number {
    return BOLD_STROKE_WIDTH_FACTOR * this.fontSize;
  }

  public getColorForStroke(): RGBA {
    const luminanceFactor = getRgbLuminance(this.fill.fillColor[0]) < 50 ? 150 : -150;
    return hexToRgba(lightenDarkenColor(this.fill.fillColor[0], luminanceFactor));
  }

  public getDefaultStrokeWidth(type: ITEM_TYPE, scaleX: number): number {
    const defaultStrokeWidth = 0.5;
    const strokeWidthFactorForText = 200;
    const strokeWidthFactorForLayouts = 50;
    let strokeWidth = defaultStrokeWidth;
    if (type === ITEM_TYPE.TEXT || type === ITEM_TYPE.TEXTSLIDE) {
      strokeWidth = this.fontSize / strokeWidthFactorForText;
    } else if (type === ITEM_TYPE.MENU || type === ITEM_TYPE.TABLE) {
      strokeWidth = (this.fontSize * scaleX) / strokeWidthFactorForLayouts;
    }

    return Math.min(Math.max(strokeWidth, defaultStrokeWidth), MAX_STROKE_WIDTH);
  }

  public getLanguageScriptAndFontForText(text: string): Partial<TextStylesObject> {
    if (!text) {
      return {};
    }

    const scriptForText = getScriptForText(text);
    if (!scriptForText) {
      this.script = '';
      return {};
    }

    return {
      script: scriptForText,
      fontFamily: isInternalFont(this.fontFamily, scriptForText) ? this.fontFamily : getRecommendedFont(scriptForText),
    };
  }
}
