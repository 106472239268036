import type {ReactElement} from 'react';
import React from 'react';
import {hideLoadingAction, LoadingVariant} from './loading-toast-reducer';
import {useAppDispatch, useAppSelector} from '@/hooks';
import {useIsMounted} from '@Hooks/useIsMounted';
import {ToastType} from '@Components/toast/toast.types';
import Toast from '@Components/toast';
import styles from './loading-toast.module.scss';
import {LOADER_COLOR} from '@Components/circular-progress-loader';

export function LoadingToast(): ReactElement {
  const loadingToast = useAppSelector((state) => {
    return state.loadingToast;
  });
  const currentLoading = useAppSelector((state) => {
    return state.loadingToast.currentLoading;
  });
  const isPosterVideo = useAppSelector((state) => {
    return state.posterEditor.isPosterVideo;
  });
  const isSaveReminderDisplaying = useAppSelector((state) => {
    return state.saveReminder.show;
  });
  const bottomBarHeight = useAppSelector((state) => {
    return state.posterEditorWebBottomBar.bottomBarHeight;
  });
  const dispatch = useAppDispatch();
  const hasMounted = useIsMounted();

  const getWrapperClasses = (): string => {
    return `radius-8 ${styles.wrapper}
     ${currentLoading.show ? styles.in : styles.out} 
     ${hasMounted.current ? styles.doAnimate : ''} 
     ${isSaveReminderDisplaying || loadingToast.isSaveReminderDisplaying ? styles.displayingSaveReminder : ''}
     ${isPosterVideo || loadingToast.isProgressBarDisplaying ? styles.displayingProgressBar : ''}`;
  };

  const getBottomForLoadingToast = (): string => {
    let bottom = 0;
    const DEFAULT_PADDING_AND_GAP = 16;
    const DEFAULT_BOTTOM_BAR_HEIGHT_WITH_PADDING = 76;
    const PROGRESS_BAR_HEIGHT = 64;
    const DEFAULT_SAVE_REMINDER_HEIGHT = 137;
    if (!isPosterVideo) {
      bottom = DEFAULT_PADDING_AND_GAP;
    } else {
      bottom = bottomBarHeight ? bottomBarHeight + DEFAULT_PADDING_AND_GAP : DEFAULT_BOTTOM_BAR_HEIGHT_WITH_PADDING;
    }

    if (loadingToast.isProgressBarDisplaying) {
      bottom += PROGRESS_BAR_HEIGHT;
    }

    if (isSaveReminderDisplaying || loadingToast.isSaveReminderDisplaying) {
      bottom += DEFAULT_SAVE_REMINDER_HEIGHT + DEFAULT_PADDING_AND_GAP;
    }

    return `${bottom}px`;
  };

  const onClose = (): void => {
    dispatch(hideLoadingAction(currentLoading.key));
  };

  return (
    <div
      style={{
        bottom: getBottomForLoadingToast(),
      }}
      className={getWrapperClasses()}
    >
      <Toast
        type={ToastType.LOADING}
        title={currentLoading.text}
        progress={currentLoading.progress ? currentLoading.progress * 100 : undefined}
        onClose={!currentLoading.hideIcon ? onClose : undefined}
        progressBarClasses={currentLoading.variant === LoadingVariant.AI ? styles.aiProgressBar : undefined}
        titleClasses={currentLoading.variant === LoadingVariant.AI ? 'ai-text-dark' : undefined}
        circularLoaderColor={currentLoading.variant === LoadingVariant.AI ? LOADER_COLOR.AI_DARK : undefined}
      />
    </div>
  );
}
