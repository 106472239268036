import type {TPointerEvent, Control} from '@postermywall/fabricjs-2';
import {InteractiveFabricObject, Textbox, Group, FabricObject, controlsUtils} from '@postermywall/fabricjs-2';
import {degreesToRadians} from '@Utils/math.util';
import md5 from 'md5';
import type {SlideshowItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import {isMobile} from 'react-device-detect';
import {loadImageAsync} from '@Utils/image.util';
import verticalRectangleSVG from './poster-item-control-svgs/vertical-rectangle.svg';
import horizontalRectangleSVG from './poster-item-control-svgs/horizontal-rectangle.svg';
import lockSVG from './poster-item-control-svgs/lock.svg';
import rotateSVG from './poster-item-control-svgs/rotate.svg';
import circleSVG from './poster-item-control-svgs/circle.svg';
import nextSlideSVG from './poster-item-control-svgs/next-slide.svg';
import previousSlideSVG from './poster-item-control-svgs/previous-slide.svg';
import pmwBmBtnSVG from './poster-item-control-svgs/pmw-bm-btn.svg';
import squareSVG from './poster-item-control-svgs/square.svg';

const controlImgs: Record<string, HTMLImageElement> = {};

interface DrawControlOptions {
  offsetX?: number;
  offsetY?: number;
  maxSide?: number;
  width?: number;
  height?: number;
  opacity?: number;
}

export interface OnResizeParams {
  e: TPointerEvent;
  delta: number;
}

export const enum ItemControlOption {
  SLIDE_BTN_ACTION_NAME = 'slideBtnClicked',
}

export interface CustomControl {
  key: string;
  control: Control;
}

export enum ItemBorderColor {
  STATIC_ITEM = 'rgba(63,188,231,1)',
  DYNAMIC_ITEM = 'rgba(149, 119, 231,1)',
}

export const DEFAULT_CIRCLE_CONTROL_SIZE = 35;
export const LINE_CONTROL_SIZE = 16;
const DESKTOP_SELECTION_SIZE = 24;
const MOBILE_SELECTION_SIZE = 40;

export const ITEM_CONTROL_DIMENSIONS = {
  BORDER_THICKNESS: 2,
  DEFAULT_RECTANGLE_CONTROL_SIZE: 22,
  RECTANGLE_CONTROL_PADDING: 1,
  BOTTOM_MIDDLE_BUTTON_SIZE: 200,
  ROTATING_CONTROL_OFFSET: -30,
  UNLOCK_BUTTON_OFFSETY: -30,
  BOTTOM_BUTTONS_OFFSET: 28,
  DISABLED_OPACITY: 0.7,
  PMW_CONTROL_PADDING: 0,
  PMW_ITEM_LEGACY_PADDING: 11,
};

export const initDefaultFabricControls = (isMaskingPanel = false): void => {
  InteractiveFabricObject.ownDefaults = {
    ...InteractiveFabricObject.ownDefaults,
    borderColor: ItemBorderColor.STATIC_ITEM,
    borderOpacityWhenMoving: 1,
    borderScaleFactor: ITEM_CONTROL_DIMENSIONS.BORDER_THICKNESS,
    cornerSize: getCornerSelectableSize(),
    padding: ITEM_CONTROL_DIMENSIONS.PMW_CONTROL_PADDING,
    transparentCorners: false,
    lockScalingFlip: true,
    snapAngle: 45,
    snapThreshold: 1,
    perPixelTargetFind: true,
    noScaleCache: false,
    hasControls: isMaskingPanel,
    hasBorders: isMaskingPanel,
  };

  Group.ownDefaults = {
    ...Group.ownDefaults,
    perPixelTargetFind: false,
  };

  initControlUI();
};

export const initControlUI = (): void => {
  FabricObject.createControls = (): {controls: Record<string, Control>} => {
    return {controls: getModifedControlsForPMW(controlsUtils.createObjectDefaultControls())};
  };

  Textbox.createControls = (): {controls: Record<string, Control>} => {
    return {controls: getModifedControlsForPMW(controlsUtils.createTextboxDefaultControls())};
  };
};

const getModifedControlsForPMW = (controls: Record<string, Control>): Record<string, Control> => {
  for (const [controlId, control] of Object.entries(controls)) {
    control.render = renderControl.bind(this, controlId);

    if (controlId === 'mtr') {
      control.withConnection = false;
      control.offsetY = ITEM_CONTROL_DIMENSIONS.ROTATING_CONTROL_OFFSET;
    }

    if (controlId === 'ml' || controlId === 'mt' || controlId === 'mr' || controlId === 'mb') {
      control.visible = false;
    }
  }
  return controls;
};

const getCornerSelectableSize = (): number => {
  return isMobile ? MOBILE_SELECTION_SIZE : DESKTOP_SELECTION_SIZE;
};

export const renderControl = (key: string, ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: FabricObject | Group): void => {
  const middleCornerButtonsSizeRatio = 1.45;
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();

  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(degreesToRadians(fabricObject.angle));

  switch (key) {
    case 'mb':
      drawControlIcon(horizontalRectangleSVG as string, ctx, {
        maxSide: ITEM_CONTROL_DIMENSIONS.DEFAULT_RECTANGLE_CONTROL_SIZE * middleCornerButtonsSizeRatio,
        offsetY: ITEM_CONTROL_DIMENSIONS.RECTANGLE_CONTROL_PADDING,
      });
      break;
    case 'mt':
      drawControlIcon(horizontalRectangleSVG as string, ctx, {
        maxSide: ITEM_CONTROL_DIMENSIONS.DEFAULT_RECTANGLE_CONTROL_SIZE * middleCornerButtonsSizeRatio,
        offsetY: -ITEM_CONTROL_DIMENSIONS.RECTANGLE_CONTROL_PADDING,
      });
      break;

    case 'ml':
    case 'pmwMl':
      drawControlIcon(verticalRectangleSVG as string, ctx, {
        maxSide: Math.round(ITEM_CONTROL_DIMENSIONS.DEFAULT_RECTANGLE_CONTROL_SIZE * middleCornerButtonsSizeRatio),
        offsetX: -ITEM_CONTROL_DIMENSIONS.RECTANGLE_CONTROL_PADDING,
      });
      break;
    case 'mr':
    case 'pmwMr':
      drawControlIcon(verticalRectangleSVG as string, ctx, {
        maxSide: Math.round(ITEM_CONTROL_DIMENSIONS.DEFAULT_RECTANGLE_CONTROL_SIZE * middleCornerButtonsSizeRatio),
        offsetX: ITEM_CONTROL_DIMENSIONS.RECTANGLE_CONTROL_PADDING,
      });
      break;

    case 'pmwMt':
      drawControlIcon(horizontalRectangleSVG as string, ctx, {
        maxSide: Math.round(ITEM_CONTROL_DIMENSIONS.DEFAULT_RECTANGLE_CONTROL_SIZE * middleCornerButtonsSizeRatio),
        offsetY: -ITEM_CONTROL_DIMENSIONS.RECTANGLE_CONTROL_PADDING,
      });
      break;
    case 'pmwMb':
      drawControlIcon(horizontalRectangleSVG as string, ctx, {
        maxSide: Math.round(ITEM_CONTROL_DIMENSIONS.DEFAULT_RECTANGLE_CONTROL_SIZE * middleCornerButtonsSizeRatio),
        offsetY: ITEM_CONTROL_DIMENSIONS.RECTANGLE_CONTROL_PADDING,
      });
      break;

    case 'mtr':
      drawControlIcon(rotateSVG as string, ctx, {maxSide: Math.round(DEFAULT_CIRCLE_CONTROL_SIZE)});
      break;

    case 'lineMl':
    case 'lineMr':
      drawControlIcon(circleSVG as string, ctx, {maxSide: Math.round(LINE_CONTROL_SIZE)});
      break;

    case 'pmwBmBtn':
      drawPmwBmBtn(ctx, fabricObject);
      break;

    case 'unlockBtn':
      drawControlIcon(lockSVG as string, ctx, {maxSide: Math.round(DEFAULT_CIRCLE_CONTROL_SIZE)});
      break;

    case 'pmwPreviousSlideBtn':
      if (currentPage) {
        const item = currentPage.items.getItemForFabricObject(fabricObject) as SlideshowItem;

        item.fabricObject.controls[key].disabled = !item.slides.hasPreviousSlide();

        const opts: DrawControlOptions = {maxSide: DEFAULT_CIRCLE_CONTROL_SIZE};
        if (item.fabricObject.controls[key].disabled) {
          opts.opacity = ITEM_CONTROL_DIMENSIONS.DISABLED_OPACITY;
        }
        drawControlIcon(previousSlideSVG as string, ctx, opts);
      }
      break;

    case 'pmwNextSlideBtn':
      if (currentPage) {
        const item = currentPage.items.getItemForFabricObject(fabricObject) as SlideshowItem;

        item.fabricObject.controls[key].disabled = !item.slides.hasNextSlide();

        const opts: DrawControlOptions = {maxSide: DEFAULT_CIRCLE_CONTROL_SIZE};
        if (item.fabricObject.controls[key].disabled) {
          opts.opacity = ITEM_CONTROL_DIMENSIONS.DISABLED_OPACITY;
        }
        drawControlIcon(nextSlideSVG as string, ctx, opts);
      }
      break;

    default:
      drawControlIcon(squareSVG as string, ctx, {maxSide: ITEM_CONTROL_DIMENSIONS.DEFAULT_RECTANGLE_CONTROL_SIZE});
      break;
  }

  ctx.restore();
};

/**
 * Draws the bottom middle pmw btn
 */
const drawPmwBmBtn = (ctx: CanvasRenderingContext2D, fabricObject: FabricObject): void => {
  const TEXT_HEIGHT = 11; // This value is attained by hit and trail
  const ICON_MARGIN_RIGHT = 3;
  const BUTTON_RIGHT_LEFT_PADDING = 35;
  const BUTTON_TOP_BOTTOM_PADDING = 30;
  const ICON_MAX_SIDE = TEXT_HEIGHT + 4;
  const textFont = 'bold 14px Nunito Sans';
  const textMetrics = getTextMetrics(fabricObject.pmwBmBtnText, textFont);
  const textWidth = textMetrics?.width ?? 0;
  const buttonWidth = textWidth + ICON_MAX_SIDE + BUTTON_RIGHT_LEFT_PADDING;
  const buttonHeight = TEXT_HEIGHT + BUTTON_TOP_BOTTOM_PADDING;

  fabricObject.controls.pmwBmBtn.sizeX = buttonWidth;
  fabricObject.controls.pmwBmBtn.sizeY = buttonHeight;
  drawControlIcon(pmwBmBtnSVG as string, ctx, {width: buttonWidth, height: buttonHeight});
  drawControlIcon(fabricObject.pmwBmBtnIcon, ctx, {
    maxSide: ICON_MAX_SIDE,
    offsetX: -textWidth / 2 - ICON_MARGIN_RIGHT / 2,
  });

  ctx.font = textFont;
  ctx.fillStyle = '#4A4A4A';
  ctx.fillText(fabricObject.pmwBmBtnText, -textWidth / 2 + ICON_MAX_SIDE / 2 + ICON_MARGIN_RIGHT / 2, TEXT_HEIGHT / 2);
};

/**
 * Returns text metrics for text and font
 */
const getTextMetrics = (text: string, font: string): TextMetrics | undefined => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (ctx) {
    ctx.font = font;
    return ctx.measureText(text);
  }
  return undefined;
};

export const drawControlIcon = (svgContent: string, ctx: CanvasRenderingContext2D, opts: DrawControlOptions): void => {
  if (!isImageLoadedForSvg(svgContent)) {
    loadImageForSvg(svgContent)
      .then(() => {
        window.posterEditor?.whiteboard?.getCurrentPage().fabricCanvas.requestRenderAll();
      })
      .catch((e) => {
        console.error(e);
      });
    return;
  }

  const controlImg = getImageForSvg(svgContent);
  let cornerWidth = 0;
  let cornerHeight = 0;
  const offsetX = typeof opts.offsetX !== 'undefined' ? opts.offsetX : 0;
  const offsetY = typeof opts.offsetY !== 'undefined' ? opts.offsetY : 0;

  if (typeof opts.maxSide !== 'undefined') {
    if (controlImg.width > controlImg.height) {
      cornerWidth = opts.maxSide;
      cornerHeight = cornerWidth * (controlImg.height / controlImg.width);
    } else {
      cornerHeight = opts.maxSide;
      cornerWidth = cornerHeight * (controlImg.width / controlImg.height);
    }
  } else if (typeof opts.width !== 'undefined' && typeof opts.height !== 'undefined') {
    cornerWidth = opts.width;
    cornerHeight = opts.height;
  }

  ctx.save();
  if (typeof opts.opacity !== 'undefined') {
    ctx.globalAlpha = opts.opacity;
  }
  ctx.drawImage(controlImg, offsetX - cornerWidth / 2, offsetY - cornerHeight / 2, cornerWidth, cornerHeight);
  ctx.restore();
};

const isImageLoadedForSvg = (svgContent: string): boolean => {
  const checkSum = md5(svgContent);
  return controlImgs[checkSum] !== undefined;
};

const loadImageForSvg = async (svgContent: string): Promise<void> => {
  const checkSum = md5(svgContent);
  if (!controlImgs[checkSum]) {
    controlImgs[checkSum] = await loadImageAsync(svgContent);
  }
};

const getImageForSvg = (svgContent: string): HTMLImageElement => {
  const checkSum = md5(svgContent);
  return controlImgs[checkSum];
};
