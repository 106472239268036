import type {IconPlacement, Type} from '@Components/button';
import type {MouseEventHandler} from 'react';
import type {LOADER_COLOR} from '@Components/circular-progress-loader';

export interface ToastProps {
  title: string;
  type?: ToastType;
  progress?: number;
  className?: string;
  footerButtons?: ToastButtonProps[];
  subText?: string;
  onClose?: MouseEventHandler;
  boldTitle?: boolean;
  titleClasses?: string;
  progressBarClasses?: string;
  circularLoaderColor?: LOADER_COLOR;
}

export enum ToastType {
  SUCCESS,
  ERROR,
  LOADING,
}

export enum ToastButtonAction {
  CANCEL,
  SAVE,
  RETRY,
}

export interface ToastButtonProps {
  id: string;
  action: ToastButtonAction;
  type: Type;
  icon?: string;
  text: string;
  iconPlacement?: IconPlacement;
}
