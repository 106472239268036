import type {ReactElement} from 'react';
import styles from './progress-bar.module.scss';

interface ProgressBarProps {
  progress: number;
  className?: string;
  progressClasses?: string;
}

export function ProgressBar({...props}: ProgressBarProps): ReactElement {
  return (
    <div className={`_full-width _overflow-hidden radius-8 ${styles.progressContainer} ${props.className}`}>
      <div className={`_full-height radius-round ${styles.progressBar} ${props.progressClasses ?? ''}`} style={{width: `${Math.min(100, Math.max(0, props.progress))}%`}} />
    </div>
  );
}
