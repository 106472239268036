import type {ReactElement} from 'react';
import React, {useMemo} from 'react';
import type {ToastProps} from '@Components/toast/toast.types';
import {ToastButtonAction, ToastType} from '@Components/toast/toast.types';
import styles from './toast.module.scss';
import {Button, Size} from '@Components/button';
import {noop} from '@Utils/general.util';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {Text, TextSize, TextType} from '@Components/text';
import {ProgressBar} from '@Components/toast/components/progress-bar/progress-bar';
import {CircularProgressLoader, LOADER_COLOR, LOADER_SIZE} from '@Components/circular-progress-loader';

export default function Toast({type = ToastType.SUCCESS, boldTitle = false, circularLoaderColor = LOADER_COLOR.DARK, ...props}: ToastProps): ReactElement {
  const isLoader = type === ToastType.LOADING;
  const subText = useMemo(() => {
    return isLoader ? undefined : props.subText;
  }, [type, props.subText]);

  const getColorForLeftMargin = (): string => {
    switch (type) {
      case ToastType.LOADING:
        return '_hidden';
      case ToastType.SUCCESS:
        return styles.successMargin;
      case ToastType.ERROR:
        return styles.errorMargin;
      default:
        throw new Error(`Unhandled type for toast: ${type}`);
    }
  };

  const onClick = (action: ToastButtonAction): void => {
    switch (action) {
      case ToastButtonAction.CANCEL:
      case ToastButtonAction.RETRY:
      case ToastButtonAction.SAVE:
        noop();
        break;
      default:
        throw new Error(`Unhandled action type for toast button: ${action}`);
    }
  };

  const shouldRenderFooter = (): boolean => {
    return !isLoader && !!props.footerButtons && props.footerButtons.length > 0;
  };

  const getLoadingProgress = (): ReactElement => {
    return (
      <div className="spacing-p-2">
        <ProgressBar progress={props.progress ?? 20} progressClasses={props.progressBarClasses} />
      </div>
    );
  };

  return (
    <div className={`radius-8 _overflow-hidden border-s-standard flex-box shadow-floating-elevation-3 ${isLoader ? '' : 'flex-h-row'} ${props.className ?? ''}`}>
      {!isLoader ? <div className={`${styles.leftMargin} ${getColorForLeftMargin()}`} /> : null}

      <div className={'flex-v-row flex-1'}>
        <div className={`flexbox flex-1 flex-content-between flex-align-items-center ${styles.contentContainer}`}>
          <div className={`flex-v-row spacing-p-4`}>
            <div className="flex-center">
              {isLoader ? (
                <div className={`spacing-m-r-2 ${styles.loaderContainer}`}>
                  <CircularProgressLoader size={LOADER_SIZE.SMALL} color={circularLoaderColor} />
                </div>
              ) : null}
              <Text
                className={`content-body ${props.titleClasses ?? ''} ${isLoader ? 'spacing-m-l-2' : ''}`}
                type={TextType.BODY}
                size={TextSize.SMALL}
                bold={boldTitle}
                val={props.title}
              />
            </div>
            {subText ? <Text className="content-body" type={TextType.BODY} size={TextSize.XSMALL} val={subText} /> : null}
          </div>
          {props.onClose ? (
            <Icon
              icon={'icon-close'}
              className={isLoader ? `${styles.closeIcon} flex-center spacing-m-r-2` : ''}
              size={IconSize.SIZE_ICON_16}
              shape={IconShape.SQUARE}
              onClick={props.onClose}
              type={IconType.GHOST}
            />
          ) : null}
        </div>

        {shouldRenderFooter() ? (
          <div className={styles.footer}>
            <ul className={`spacing-m-b-0 spacing-p-3 flex-justify-end ${styles.ctaContainer}`}>
              {props.footerButtons?.reverse().map((item) => {
                return (
                  <li key={item.id} className={'spacing-p-b-0'}>
                    <Button
                      size={Size.SMALL}
                      type={item.type}
                      id={item.id}
                      onClick={(e) => {
                        e.stopPropagation();
                        onClick(item.action);
                      }}
                      text={item.text}
                      icon={item.icon}
                      iconPlacement={item.iconPlacement}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}

        {isLoader && props.progress && props.progress > 0 ? getLoadingProgress() : null}
      </div>
    </div>
  );
}
