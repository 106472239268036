import type {ReactElement} from 'react';
import React, {useEffect, useState} from 'react';
import {Modal} from '@Components/modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import type {UserMediaPanelProps} from '@Panels/user-media-panel/user-media-panel';
import useWindowSize from '@Hooks/useWindowSize';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'user-media-modal-panel';

export type UserMediaModalProps = UserMediaPanelProps;

export default function UserMediaModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.USER_MEDIA_MODAL];
  });
  const sizeData = useAppSelector((state) => {
    return state.panels.sizeDataHashmap[PANEL_ID];
  });

  const stickToBottom = useAppSelector((state) => {
    return state.panels.panelPropertiesHashmap[PANEL_ID]?.stickToBottom;
  });
  const {windowWidth} = useWindowSize();

  useEffect((): void => {
    if (sizeData?.height) {
      setHeight(sizeData.height);
    }

    if (sizeData?.width) {
      setWidth(sizeData.width);
    }
  }, [sizeData]);

  const getInitialWidth = (): string => {
    if (windowWidth > 2000) {
      return '1600px';
    }
    if (windowWidth <= 2000 && windowWidth > 550) {
      return '80%';
    }
    return '600px';
  };
  const [height, setHeight] = useState('94%');
  const [width, setWidth] = useState(getInitialWidth());

  return (
    <Modal
      modalId={MODALS_IDS.USER_MEDIA_MODAL}
      stickToBottom={stickToBottom}
      mobileHeight="800px"
      isBottomSheetOnMobile
      doesBottomSheetHaveSnapPoints
      panelId={PANEL_ID}
      modalHeight={height}
      modalWidth={width}
    >
      <LazyPanel
        panelDirectoryName="user-media-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openUserMediaModal = (props: UserMediaModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.USER_MEDIA_MODAL,
      panelProps: props,
    })
  );
};