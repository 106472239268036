import {noop} from '@Utils/general.util';
import {generatePosterPreview} from '@Libraries/poster-library';
import {isBeta, isLocal} from '@Libraries/environment-library';

export type EmbeddedEditorCallback = (designId: string) => void;

export enum EmbeddedEditorAction {
  COPY = 'copy',
  EDIT = 'edit',
  MORPH = 'morph',
}

export enum EmbeddedEditorMode {
  NORMAL = 'normal',
  EXPORT = 'export',
}

export enum EmbeddedEditorMessageType {
  SAVE = 'Save',
  CLOSE = 'Close~iframe',
  SILENT_CLOSE = 'SilentClose~iframe',
  RENDER_CLOSE_BTN = 'IsPosterMaker~0',
  HIDE_CLOSE_BTN = 'IsPosterMaker~1',
  READY_FOR_MODE = 'ReadyForMode~1',
  FREEBIE_URL = 'FreebieURL',
}

export interface EmbeddedEditorDimensionsParam {
  width: number;
  height: number;
  units: string;
}

export type EmbeddedEditorOpenGalleryConfig = EmbeddedEditorContentTypeParam & EmbeddedEditorOpenDesignConfig;

export enum EmbeddedEditorElementsToHide {
  DOWNLOAD_BUTTON = 'download',
  FILE_DROPDOWN = 'file',
  UPGRADE_BUTTON = 'upgrade',
  PREVIEW = 'preview',
}

export enum EmbeddedEditorContentType {
  IMAGE = 'image',
  VIDEO = 'video',
  ALL = 'all',
}

export interface EmbeddedEditorContentTypeParam {
  contentType?: EmbeddedEditorContentType;
}

export interface EmbeddedEmailEditorOpenEmailConfig {
  onClose?: VoidFunction;
  nextBtnCaption?: string;
  elementsToHide?: EmbeddedEditorElementsToHide[];
  onSave?: EmbeddedEditorCallback;
  affiliateCode?: string;
  language?: string;
  encodedAutoUpdateContentParams?: string;
}

export interface EmbeddedEditorOpenDesignConfig {
  onClose?: VoidFunction;
  mode?: EmbeddedEditorMode;
  sizeFilter?: string;
  elementsToHide?: EmbeddedEditorElementsToHide[];
  nextBtnCaption?: string;
  onSave?: EmbeddedEditorCallback;
  affiliateCode?: string;
  dimensions?: EmbeddedEditorDimensionsParam;
  language?: string;
  getParams?: string;
}

export const openEmailInEmbeddedEditor = (
  emailId: string,
  onOpened: VoidFunction = noop,
  onSave: EmbeddedEditorCallback = noop,
  onClose: VoidFunction = noop,
  action: EmbeddedEditorAction = EmbeddedEditorAction.EDIT,
  customNextButtonCaption?: string,
  encodedAutoUpdateContentParams?: string,
  hidePreviewButton = false
): void => {
  const elementsToHide: EmbeddedEditorElementsToHide[] = [];
  if (hidePreviewButton) {
    elementsToHide.push(EmbeddedEditorElementsToHide.PREVIEW);
  }

  if (typeof window.PMW.plugin === 'undefined') {
    pluginEditorInitScript();
    window.pmwPluginEditorInit = (): void => {
      onOpened();
      doOpenEmailInEmbeddedEditor(emailId, onSave, onClose, action, customNextButtonCaption, encodedAutoUpdateContentParams, elementsToHide);
    };

    return;
  }

  onOpened();
  doOpenEmailInEmbeddedEditor(emailId, onSave, onClose, action, customNextButtonCaption, encodedAutoUpdateContentParams, elementsToHide);
};

export const openDesignInEmbeddedEditor = (
  designId: string,
  onOpened: VoidFunction = noop,
  onSave: EmbeddedEditorCallback = noop,
  onClose: VoidFunction = noop,
  action: EmbeddedEditorAction = EmbeddedEditorAction.EDIT,
  customNextButtonCaption?: string,
  opts?: Record<string, any>
): void => {
  if (typeof window.PMW.plugin === 'undefined') {
    pluginEditorInitScript();
    window.pmwPluginEditorInit = (): void => {
      onOpened();
      doOpenDesignInEmbeddedEditor(designId, onSave, onClose, action, customNextButtonCaption, opts);
    };

    return;
  }

  onOpened();
  doOpenDesignInEmbeddedEditor(designId, onSave, onClose, action, customNextButtonCaption, opts);
};

const doOpenEmailInEmbeddedEditor = (
  emailId: string,
  onSave: EmbeddedEditorCallback,
  onClose: VoidFunction,
  action: EmbeddedEditorAction = EmbeddedEditorAction.EDIT,
  customNextButtonCaption = '',
  encodedAutoUpdateContentParams = '',
  elementsToHide: EmbeddedEditorElementsToHide[] = []
): void => {
  window.PMW.plugin.editor.openEmail(emailId, action, {
    onSave: (savedEmailId) => {
      onSave(savedEmailId);
      window.PMW.plugin.editor.close();
    },
    onClose,
    nextBtnCaption: customNextButtonCaption,
    encodedAutoUpdateContentParams,
    elementsToHide,
  });
};
const doOpenDesignInEmbeddedEditor = (
  designId: string,
  onSave: EmbeddedEditorCallback,
  onClose: VoidFunction,
  action: EmbeddedEditorAction = EmbeddedEditorAction.EDIT,
  customNextButtonCaption = '',
  opts: Record<string, any> = {}
): void => {
  let getParams;
  if (Object.keys(opts).length > 0) {
    if ('getParams' in opts) {
      getParams = Object.keys(opts.getParams as Record<string, string>)
        .map((key) => {
          const value = (opts.getParams as Record<string, string>)[key];
          return `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');
    }
  }

  window.PMW.plugin.editor.openDesign(designId, action, {
    onSave: (savedDesignId) => {
      onSave(savedDesignId);
      window.PMW.plugin.editor.close();
    },
    onClose,
    nextBtnCaption: customNextButtonCaption,
    elementsToHide: [EmbeddedEditorElementsToHide.DOWNLOAD_BUTTON, EmbeddedEditorElementsToHide.UPGRADE_BUTTON, EmbeddedEditorElementsToHide.FILE_DROPDOWN],
    getParams,
  });
};

export const closeEmbeddedEditor = (): void => {
  window.parent.postMessage(EmbeddedEditorMessageType.CLOSE, '*');
};

export const closeEmbeddedEditorWithoutCallback = (): void => {
  window.parent.postMessage(EmbeddedEditorMessageType.SILENT_CLOSE, '*');
};

const pluginEditorInitScript = (): void => {
  ((d, s, id): void => {
    const pjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    const js = d.createElement(s);
    js.id = id;

    if (isLocal()) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      js.src = 'https://localpostermywall.com/pmw/assets/javascript/plugins/embedded-editor/postermywall-editor-v5.js';
    } else if (isBeta()) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      js.src = 'https://beta.postermywall.com/assets/javascript/plugins/embedded-editor/postermywall-editor-v5.js';
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      js.src = `https://d1csarkz8obe9u.cloudfront.net/plugins/editor/postermywall-editor-v5.js?ts=${Date.now()}`;
    }
    pjs.parentNode?.insertBefore(js, pjs);
  })(document, 'script', 'pmw-plugin-editor');
};
