import type {PosterOpts} from '@PosterWhiteboard/poster/poster.class';
import {Poster} from '@PosterWhiteboard/poster/poster.class';
import type {AiTextMorphData, PosterLoadObject, PosterPageObject} from '@PosterWhiteboard/poster/poster.types';
import {MAX_POSTER_NAME_LENGTH, POSTER_VERSION} from '@PosterWhiteboard/poster/poster.types';
import {getPosterObjectFromPosterBackendObject} from '@PosterWhiteboard/poster/poster-backend';
import {getFitToScreenScale, modeHasBufferForFitToScreen} from '@PosterWhiteboard/poster/poster-scaling.class';
import {hideLoading, showLoading, updateLoadingProgress} from '@Libraries/loading-toast-library';
import {getUniqueString, incrementVersionInString} from '@Utils/string.util';
import {geCurrentUserData} from '@Libraries/user.library';
import type {MorphData, PosterModeDetails} from '@PosterWhiteboard/poster/poster-mode.class';
import {MorphType, PosterMode, PosterModeType} from '@PosterWhiteboard/poster/poster-mode.class';
import {BackgroundTypeName} from '@PosterWhiteboard/page/background/background.class';
import {FillTypes} from '@PosterWhiteboard/classes/fill.class';
import {resizeToUpdatedDimensions} from '@PosterWhiteboard/libraries/resize-poster.library';
import {GA4EventName, GA4EventParamName, trackPosterBuilderGA4Events} from '@Libraries/ga-events';
import type {UserObject} from '@PosterWhiteboard/user/user.types';
import type {FolderObject} from '@PosterWhiteboard/classes/folder.class';
import type {PosterObjectBackend} from '@PosterWhiteboard/poster/poster-backend.types';
import {getPosterBackendObjectFromPoster} from '@PosterWhiteboard/poster/poster-frontend-to-backend';
import {handleEventsPreFillMorphingForPoster} from '@Components/event-wizard/event-wizard-image-video/event-wizard-image-video.helper';
import type {EventsPrefillMorphData} from '@Components/event-wizard/event-wizard-image-video/event-wizard-image-video.types';
import type {DeepPartial} from '@/global';
import {showInitalPosterItemsLoadError} from '@Components/inital-poster-items-load-error/inital-poster-items-load-error.reducer';
import {handleAiTextMorphForPoster} from '@Libraries/poster-library';
import {openErrorModal} from '@Modals/error-modal';
import {setPosterOverlayLoaderVisibility} from '@Components/poster-editor/poster-editor-reducer';
import {LoadingVariant} from '@Components/loading-toast';

export interface PosterLoadOptions {
  onBackendObjectLaoded?(): void;
}

interface LoadPosterDataResponse {
  folder?: FolderObject;
  posterBackendObject: PosterObjectBackend;
  isFullPosterSaveRequired: boolean;
  contributorFontsOnPoster: any[];
}
// eslint-disable-next-line @typescript-eslint/naming-convention
export const PosterMorphLoadingKey = 'PosterMorphLoadingKey';

const loadPosterData = async (posterHashId: string, modeDetails: PosterModeDetails, posterVersionId?: string): Promise<LoadPosterDataResponse> => {
  return (await window.PMW.readLocal('posterbuilder/loadPosterData', {
    hashedId: posterHashId,
    versionId: posterVersionId,
    posterVersionToLoad: POSTER_VERSION.CURRENT,
    jsVersion: window.PMW.VERSION,
    htmlVersion: window.PMW.HTML_VERSION,
    modeDetails,
  })) as LoadPosterDataResponse;
};

export const loadPosterTemplateFromHashId = async (posterHashId: string): Promise<void> => {
  if (!window.posterEditor?.whiteboard) {
    return;
  }

  const {posterBackendObject} = await loadPosterData(posterHashId, window.posterEditor.whiteboard.mode.details);
  const posterObject = getPosterObjectFromPosterBackendObject(posterBackendObject);

  await window.posterEditor.whiteboard.replacePoster(posterObject);
};

export const terminatePosterMorphLoading = (key: string): void => {
  window.PMW.redux.store.dispatch(setPosterOverlayLoaderVisibility(false));
  hideLoading(key);
};

export async function loadPoster(htmlElement: HTMLDivElement, {onBackendObjectLaoded = (): void => {}}: PosterLoadOptions, posterOpts: PosterOpts): Promise<Poster> {
  const {mode, ...posterOptsWithoutMode} = posterOpts;

  const posterMode = new PosterMode(mode.details);
  if (posterMode.details.type === PosterModeType.CREATE) {
    throw new Error(`Invalid poster mode type`);
  }

  if (mode.details.type === PosterModeType.MORPH) {
    initiatePosterMorphLoading(PosterMorphLoadingKey);
  }

  let posterBackendObject,
    folder,
    isFullPosterSaveRequired = true;
  try {
    const response = await loadPosterData(posterMode.details.posterHashId, mode.details, posterMode.details.posterVersionId);
    posterBackendObject = response.posterBackendObject;
    folder = response.folder;
    isFullPosterSaveRequired = response.isFullPosterSaveRequired;
  } catch (e: unknown) {
    openErrorModal();
    throw e;
  } finally {
    if (mode.details.type === PosterModeType.MORPH) {
      terminatePosterMorphLoading(PosterMorphLoadingKey);
    }
  }
  showLoading('loadingPoster');
  onBackendObjectLaoded();

  const poster = new Poster(htmlElement, mode.details);

  let posterObject = getPosterObjectFromPosterBackendObject(posterBackendObject);
  const savedPosterBackendObject = getPosterBackendObjectFromPoster(posterObject);

  if (posterMode.isCopyOfAPoster()) {
    posterObject = await getNewPosterObjectFromTemplate(posterObject);
    trackPosterBuilderGA4Events(GA4EventName.CREATE_POSTER, {[GA4EventParamName.TYPE]: posterMode.details.type});
  }

  if (posterMode.details.type === PosterModeType.GENERATE && posterMode.details.optimizeItemLoad) {
    posterObject = removeAudioFromPosterObject(posterObject);
  }

  if (!posterObject.width || !posterObject.height) {
    throw new Error(`Poster has invalid dimensions`);
  }
  try {
    // const posterObjectToLoad = mode.details.type === PosterModeType.MORPH ? await morphPoster(posterObject, mode.details.data) : posterObject;
    const posterObjectToLoad = posterObject;

    if (window.posterEditor) {
      window.posterEditor.whiteboard = poster;
    }
    await poster.updateFromObject(
      {
        scaling: {
          scale: getFitToScreenScale(posterObject.width, posterObject.height, modeHasBufferForFitToScreen(posterMode.details.type)),
        },
        folder,
        ...posterOptsWithoutMode,
        ...posterObjectToLoad,
      },
      {
        updateRedux: false,
        undoable: false,
        onItemAddFail: onInitialItemLoadError,
      }
    );
    // In case user is making a copy of his own design, update the list of copied menu items
    if (Number(poster.creator?.id) === window.PMW.getUserId()) {
      updateCopiedMenuItems(poster);
    }
    poster.version = POSTER_VERSION.CURRENT;
    if (poster.mode.isCopy()) {
      await enableReplaceButtonForImageItems(poster);
      poster.savePoster.savedParentPosterBackendObject = poster.getBackendObject();
    }

    if (!isFullPosterSaveRequired) {
      poster.savePoster.setIsFullPosterSaveRequired(false);
      poster.savePoster.updateSavedPosterObject(savedPosterBackendObject);
    }
    poster.onPosterInit();
    resizePosterAfterLoad(poster.mode);
  } finally {
    hideLoading('loadingPoster');
  }
  return poster;
}

const onInitialItemLoadError = (): void => {
  window.PMW.redux.store.dispatch(showInitalPosterItemsLoadError());
};

/**
 * @deprecated Morphing is handled on backend at the load poster data stage
 * @param posterObject
 * @param morphData
 */
export const morphPoster = async (posterObject: PosterLoadObject, morphData: MorphData): Promise<PosterLoadObject> => {
  if (!('type' in morphData)) {
    throw new Error('No morph type in given morphData');
  }
  const {type, ...morphExtendedData} = morphData;
  return morphPosterWithType(posterObject, type as MorphType, morphExtendedData);
};

const morphPosterWithType = async (posterObject: PosterLoadObject, type: MorphType, morphData: MorphData): Promise<PosterLoadObject> => {
  switch (type) {
    case MorphType.EVENTS_PREFILL:
      return handleEventsPreFillMorphingForPoster(posterObject, morphData as EventsPrefillMorphData);
    case MorphType.AI_TEXT_MORPH:
      return handleAiTextMorphForPoster(posterObject, morphData as AiTextMorphData);
    default:
      throw new Error(`unhandled morph type: ${type}`);
  }
};

const removeAudioFromPosterObject = (templateObject: PosterLoadObject): PosterLoadObject => {
  return {
    ...templateObject,
    audioClips: {
      ...templateObject.audioClips,
      audioItemsHashMap: {},
    },
  };
};

export async function createPoster(htmlElement: HTMLDivElement, posterOpts: PosterOpts): Promise<Poster> {
  const {mode, ...posterOptsWithoutMode} = posterOpts;
  if (mode.details.type !== PosterModeType.CREATE) {
    throw new Error(`Invalid poster mode type`);
  }

  const poster = new Poster(htmlElement, mode.details);

  let posterObject: DeepPartial<PosterLoadObject> = {
    type: mode.details.posterType,
  };
  posterObject = await getNewPosterObjectFromTemplate(posterObject);

  try {
    if (window.posterEditor) {
      window.posterEditor.whiteboard = poster;
    }
    await poster.updateFromObject(
      {
        scaling: {
          scale: getFitToScreenScale(mode.details.posterWidth, mode.details.posterHeight, modeHasBufferForFitToScreen(mode.details.type)),
        },
        width: mode.details.posterWidth,
        height: mode.details.posterHeight,
        userWidth: mode.details.userWidth,
        userHeight: mode.details.userHeight,
        units: mode.details.units,
        pages: getNewPagesObject(),
        ...posterOptsWithoutMode,
        ...posterObject,
      },
      {updateRedux: false, undoable: false}
    );
    poster.onPosterInit();
  } finally {
    hideLoading('loadingPoster');
  }
  return poster;
}

const updateCopiedMenuItems = (poster: Poster): void => {
  const items = poster.getAllItems();
  for (const item of items) {
    if (item.isMenu()) {
      item.updateCopiedItems(item.itemIds);
    }
  }
};

const getNewPagesObject = (): DeepPartial<PosterPageObject> => {
  const pageHashId = getUniqueString();
  return {
    pagesHashMap: {
      [pageHashId]: {
        hashedID: pageHashId,
        background: {
          details: {
            type: BackgroundTypeName.COLOR,
            fill: {
              fillType: FillTypes.SOLID,
              fillColor: [[255, 255, 255, 1]],
            },
          },
        },
      },
    },
    pageOrder: [pageHashId],
  };
};

const resizePosterAfterLoad = (mode: PosterMode): void => {
  if (mode.details.type === PosterModeType.RESIZE_TO) {
    resizeToUpdatedDimensions(mode.details);
  }
};

const enableReplaceButtonForImageItems = async (poster: Poster): Promise<void> => {
  const promises = [];
  const imageItems = poster.getAllImageItems();
  for (const imageItem of imageItems) {
    promises.push(
      imageItem.updateFromObject(
        {
          showReplaceButton: true,
        },
        {
          undoable: false,
          updateRedux: false,
        }
      )
    );
  }

  await Promise.all(promises);
};

const getNewPosterObjectFromTemplate = async <T extends DeepPartial<PosterLoadObject>>(templateObject: T): Promise<T> => {
  let isUserCreatorOfTemplate = false;
  const newPosterObject: T = {
    ...templateObject,
    id: '',
    name: 'A New Design',
    description: '',
    isInternal: false,
    isTemplate: false,
    isPublic: false,
    isCopyable: true,
    isPurchaseable: true,
    createdOn: 0,
    lastModified: 0,
    idLastModifier: undefined,
    idTemplate: templateObject.id,
    idGalleryTemplate: templateObject.idGalleryTemplate,
  };

  if (templateObject.creator) {
    newPosterObject.owner = templateObject.creator;

    if (Number(templateObject.creator.id) === window.PMW.getUserId()) {
      newPosterObject.description = templateObject.description;
      isUserCreatorOfTemplate = true;
    }

    if (isUserCreatorOfTemplate) {
      newPosterObject.name = incrementVersionInString(templateObject.name ?? '', MAX_POSTER_NAME_LENGTH);
    } else {
      newPosterObject.name = templateObject.name ?? '';
    }
  }

  newPosterObject.creator = await getCurrentUserObject();
  return newPosterObject;
};

const getCurrentUserObject = async (): Promise<UserObject | undefined> => {
  const userData = await geCurrentUserData();
  if (!userData) {
    return undefined;
  }

  return {
    id: userData.id,
    fbId: userData.fbId,
    name: userData.name,
    type: userData.type,
    watermark: userData.watermark !== '-1' ? userData.watermark : undefined,
    premiumLevel: userData.premiumLevel,
    preferredLanguage: userData.preferredLanguage,
    verificationNeededStatus: userData.verificationNeededStatus,
  };
};

export const initiatePosterMorphLoading = (key: string): void => {
  window.PMW.redux.store.dispatch(setPosterOverlayLoaderVisibility(true));

  showLoading(key, {
    text: window.i18next.t('pmwjs_updating_with_ai'),
    priority: 10,
    progress: 0,
    variant: LoadingVariant.AI,
  });
  const progressUpdates = [
    {progress: 0.18, delay: 2000},
    {progress: 0.37, delay: 1000},
    {progress: 0.55, delay: 700},
    {progress: 0.88, delay: 800},
    {progress: 0.94, delay: 1000},
  ];

  let cumulativeDelay = 0;

  progressUpdates.forEach(({progress, delay}) => {
    cumulativeDelay += delay;
    setTimeout(() => {
      updateLoadingProgress(key, progress);
    }, cumulativeDelay);
  });
};
