import type {PayloadAction} from '@reduxjs/toolkit';
import {createSelector, createSlice} from '@reduxjs/toolkit';
import type {PosterObject, PosterPageObject} from '@PosterWhiteboard/poster/poster.types';
import type {ItemObject} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {PageObject} from '@PosterWhiteboard/page/page.types';
import type {AudioItemObject} from '@PosterWhiteboard/classes/audio-clips/audio-item.class';
import type {AiState} from '@Components/poster-editor/components/poster-ai/poster-ai.types';
import {AiImageState, AiSubtitleState, AiVoiceState} from '@Components/poster-editor/components/poster-ai/poster-ai.types';
import type {CopyableItemStylesAndProperties} from '@Components/poster-editor/components/poster-editing-side-panel/components/poster-item-controls/poster-item-controls.types';
import type {TranscriptItemObject} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';
import {ActivityIndicatorType} from '@Components/activity-indicator/activity-indicator.types';
import type {VideoItemObject} from '@PosterWhiteboard/items/video-item/video-item.class';
import type {AudioClipsObject} from '@PosterWhiteboard/classes/audio-clips/audio-clips.class';
import type {SuggestionItems} from '@Components/input-suggestions-dropdown/input-suggestions-dropdown.types';
import type {RootState} from '@/store';
import type {DeepPartial} from '@/global';
import type {SubtitleObject} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle.types';
import type {FancyTextItemObject} from '@PosterWhiteboard/items/fancy-text-item/fancy-text-item.class';
import type {TextItemObject} from '@PosterWhiteboard/items/text-item/text-item.types';
import type {ScrollState} from '@Components/poster-editor/poster-editor.types';

export interface UpdatePosterDataProps {
  posterObject?: PosterObject;
  hasUndo?: boolean;
  hasUnsavedChanges?: boolean;
  hasRedo?: boolean;
  isVideo?: boolean;
  currentPageId?: string;
  activeItemIds?: string[];
  isPosterVideo?: boolean;
}

interface UpdateItemDataPayload {
  pageHashId: string;
  itemHashId: string;
  obj: DeepPartial<ItemObject>;
}

interface UpdateAudioItemIsPlayingPayload {
  audioItemUID: string;
  isPlaying: boolean;
}

interface Dimensions {
  width: number;
  height: number;
}

interface StateProps {
  posterObject: PosterObject | null;
  hasUndo: boolean;
  isPosterSaving: boolean;
  didPosterSaveFailed: boolean;
  arePosterItemsMoving: boolean;
  arePosterItemsScaling: boolean;
  arePosterItemsRotating: boolean;
  didPosterRecentlySave: boolean;
  hasUnsavedChanges: boolean;
  hasRedo: boolean;
  isVideo: boolean;
  isPlaying: boolean;
  currentTime: number;
  currentPageId: string;
  isEmbeddedExportMode: boolean;
  activeItemIds: string[];
  hoveredItemId: string | undefined;
  isPosterVideo?: boolean;
  isSpellCheckSettingsActive?: boolean;
  isSidebarOpen: boolean;
  isSidebarSmall?: boolean;
  isMobileVariant?: boolean;
  isVerticalScrollbarVisible?: boolean;
  isHorizontalScrollbarVisible?: boolean;
  isPickingModeEnabled?: boolean;
  canvasDimensions: Dimensions;
  whiteboardDimensions: Dimensions;
  whiteboardContainerDimensions: Dimensions;
  isBottomWebSeekbarExpanded?: boolean;
  aiState: AiState;
  scrollState: ScrollState;
  copiedStyles?: CopyableItemStylesAndProperties;
  isUploadingToMyUploads: boolean;
  isPublishDropDownOpen: boolean;
  inputSuggestions?: SuggestionItems;
  businessAddressSuggestions?: SuggestionItems;
  showAutoSaveTooltip: boolean;
  haveShownAutoSaveTooltip: boolean;
  isPosterOverlayLoaderActive: boolean;
  isTouchGestureBeingApplied?: boolean;
}

const initialState: StateProps = {
  hasUndo: false,
  isPosterSaving: false,
  didPosterSaveFailed: false,
  arePosterItemsMoving: false,
  arePosterItemsScaling: false,
  arePosterItemsRotating: false,
  didPosterRecentlySave: false,
  hasRedo: false,
  hasUnsavedChanges: false,
  isVideo: false,
  isEmbeddedExportMode: false,
  isPlaying: false,
  currentTime: 0,
  currentPageId: '',
  posterObject: null,
  activeItemIds: [],
  hoveredItemId: undefined,
  isMobileVariant: false,
  isVerticalScrollbarVisible: false,
  isHorizontalScrollbarVisible: false,
  isPosterVideo: false,
  isSpellCheckSettingsActive: false,
  isSidebarOpen: true,
  isSidebarSmall: false,
  isPickingModeEnabled: false,
  whiteboardDimensions: {
    height: 0,
    width: 0,
  },
  canvasDimensions: {
    height: 0,
    width: 0,
  },
  whiteboardContainerDimensions: {
    height: 0,
    width: 0,
  },
  isBottomWebSeekbarExpanded: false,
  aiState: {
    aiImage: AiImageState.IDLE,
    aiSubtitles: AiSubtitleState.IDLE,
    aiVoice: AiVoiceState.IDLE,
  },
  isUploadingToMyUploads: false,
  isPublishDropDownOpen: false,
  showAutoSaveTooltip: false,
  haveShownAutoSaveTooltip: false,
  copiedStyles: undefined,
  isPosterOverlayLoaderActive: false,
  scrollState: {
    horizontalScroll: 0,
    verticalScroll: 0,
  },
  isTouchGestureBeingApplied: false,
};

const posterEditorSlice = createSlice({
  name: 'posterEditor',
  initialState,
  reducers: {
    updatePosterData(state, action: PayloadAction<UpdatePosterDataProps>) {
      if (action.payload.posterObject !== undefined) {
        // @ts-ignore
        state.posterObject = action.payload.posterObject;
      }
      if (action.payload.hasUndo !== undefined) {
        state.hasUndo = action.payload.hasUndo;
      }
      if (action.payload.hasUnsavedChanges !== undefined) {
        state.hasUnsavedChanges = action.payload.hasUnsavedChanges;
      }
      if (action.payload.currentPageId !== undefined) {
        state.currentPageId = action.payload.currentPageId;
      }
      if (action.payload.hasRedo !== undefined) {
        state.hasRedo = action.payload.hasRedo;
      }
      if (action.payload.isVideo !== undefined) {
        state.isVideo = action.payload.isVideo;
      }
      if (action.payload.isPosterVideo !== undefined) {
        state.isPosterVideo = action.payload.isPosterVideo;
      }
    },
    setPosterOverlayLoaderVisibility(state, action: PayloadAction<boolean>) {
      state.isPosterOverlayLoaderActive = action.payload;
    },
    restoreInitialData(state) {
      state.didPosterSaveFailed = initialState.didPosterSaveFailed;
      state.arePosterItemsScaling = initialState.arePosterItemsScaling;
      state.arePosterItemsMoving = initialState.arePosterItemsMoving;
      state.arePosterItemsRotating = initialState.arePosterItemsRotating;
      state.didPosterRecentlySave = initialState.didPosterRecentlySave;
    },
    updateHasUndo(state, action: PayloadAction<boolean>) {
      state.hasUndo = action.payload;
    },
    updateIsPosterSaving(state, action: PayloadAction<boolean>) {
      state.isPosterSaving = action.payload;
    },
    updateDidPosterSaveFailed(state, action: PayloadAction<boolean>) {
      state.didPosterSaveFailed = action.payload;
    },
    updateArePosterItemsMoving(state, action: PayloadAction<boolean>) {
      state.arePosterItemsMoving = action.payload;
    },
    updateArePosterItemsScaling(state, action: PayloadAction<boolean>) {
      state.arePosterItemsScaling = action.payload;
    },
    updateArePosterItemsRotating(state, action: PayloadAction<boolean>) {
      state.arePosterItemsRotating = action.payload;
    },
    onPosterItemsModified(state) {
      state.arePosterItemsMoving = false;
      state.arePosterItemsScaling = false;
      state.arePosterItemsRotating = false;
    },
    updateItemData(state, action: PayloadAction<UpdateItemDataPayload>) {
      if (!state.posterObject) return;

      const {pageHashId, itemHashId, obj} = action.payload;
      const item = state.posterObject.pages.pagesHashMap[pageHashId]?.items.itemsHashMap[itemHashId];

      if (!item) return;

      Object.assign(item, {
        ...('x' in obj && {x: obj.x}),
        ...('y' in obj && {y: obj.y}),
        ...('scaleX' in obj && {scaleX: obj.scaleX}),
        ...('scaleY' in obj && {scaleY: obj.scaleY}),
        ...('rotation' in obj && {rotation: obj.rotation}),
      });
    },
    updateDidPosterRecentlySave(state, action: PayloadAction<boolean>) {
      state.didPosterRecentlySave = action.payload;
    },
    updateIsEmbeddedExportMode(state, action: PayloadAction<boolean>) {
      state.isEmbeddedExportMode = action.payload;
    },
    updateHasRedo(state, action: PayloadAction<boolean>) {
      state.hasRedo = action.payload;
    },
    updateIsPosterEditorMobileVariant(state, action: PayloadAction<boolean>) {
      state.isMobileVariant = action.payload;
    },
    updateActiveItems(state, action: PayloadAction<string[]>) {
      state.activeItemIds = action.payload;
    },
    updateHoveredItem(state, action: PayloadAction<string | undefined>) {
      state.hoveredItemId = action.payload;
    },
    setSelectedAudioItemUID(state, action: PayloadAction<string>) {
      if (state.posterObject) {
        state.posterObject.audioClips.selectedAudioItemUID = action.payload;
      }
    },
    updateCurrentPageId(state, action: PayloadAction<string>) {
      state.currentPageId = action.payload;
    },
    updateAudioItemIsPlaying(state, action: PayloadAction<UpdateAudioItemIsPlayingPayload>) {
      if (state.posterObject) {
        if (state.posterObject.audioClips.audioItemsHashMap[action.payload.audioItemUID]) {
          state.posterObject.audioClips.audioItemsHashMap[action.payload.audioItemUID].audioPlayer.isPlaying = action.payload.isPlaying;
        }
      }
    },
    updatePosterIsVideo(state, action: PayloadAction<boolean>) {
      state.isPosterVideo = action.payload;
    },
    updateIsPlaying(state, action: PayloadAction<boolean>) {
      state.isPlaying = action.payload;
    },
    updateCurrentTime(state, action: PayloadAction<number>) {
      state.currentTime = action.payload;
    },
    updatePosterWhiteboardDimensions(state, action: PayloadAction<Dimensions>) {
      state.whiteboardDimensions = action.payload;
    },
    updatePosterWhiteboardContainerDimensions(state, action: PayloadAction<Dimensions>) {
      state.whiteboardContainerDimensions = action.payload;
    },
    updateCanvasDimensions(state, action: PayloadAction<Dimensions>) {
      state.canvasDimensions = action.payload;
    },
    updateSpellCheckSettingsState(state, action: PayloadAction<boolean>) {
      state.isSpellCheckSettingsActive = action.payload;
    },
    updateSidebarState(state, action: PayloadAction<boolean>) {
      state.isSidebarOpen = action.payload;
    },
    updateCollapsableSidebarState(state, action: PayloadAction<boolean>) {
      state.isSidebarSmall = action.payload;
    },
    updatePickingMode(state, action: PayloadAction<boolean>) {
      state.isPickingModeEnabled = action.payload;
    },
    updateVerticalScrollbarState(state, action: PayloadAction<boolean>) {
      state.isVerticalScrollbarVisible = action.payload;
    },
    updateHorizontalScrollbarState(state, action: PayloadAction<boolean>) {
      state.isHorizontalScrollbarVisible = action.payload;
    },
    updateBottomWebSeekbarState(state, action: PayloadAction<boolean>) {
      state.isBottomWebSeekbarExpanded = action.payload;
    },
    updateAiImageState(state, action: PayloadAction<AiImageState>) {
      state.aiState.aiImage = action.payload;
    },
    updateAiSubtitleState(state, action: PayloadAction<AiSubtitleState>) {
      state.aiState.aiSubtitles = action.payload;
    },
    updateAiVoiceState(state, action: PayloadAction<AiVoiceState>) {
      state.aiState.aiVoice = action.payload;
    },
    updateIsUploadingToMyUploads(state, action: PayloadAction<boolean>) {
      if (state.isUploadingToMyUploads !== action.payload) {
        state.isUploadingToMyUploads = action.payload;
      }
    },
    updatePublishDropDownState(state, action: PayloadAction<boolean>) {
      state.isPublishDropDownOpen = action.payload;
    },
    setInputSuggestions(state, action: PayloadAction<SuggestionItems>) {
      state.inputSuggestions = action.payload;
    },
    setBusinessAddress(state, action: PayloadAction<SuggestionItems>) {
      state.businessAddressSuggestions = action.payload;
    },
    updateAutoSaveTooltipState(state, action: PayloadAction<boolean>) {
      state.showAutoSaveTooltip = action.payload;
      if (action.payload) {
        state.haveShownAutoSaveTooltip = true;
      }
    },
    updateCopiedStylesState(state, action: PayloadAction<CopyableItemStylesAndProperties | undefined>) {
      state.copiedStyles = action.payload;
    },
    updateCanvasScroll(state, action: PayloadAction<DeepPartial<ScrollState>>) {
      const scrollX = action.payload.horizontalScroll;
      const scrollY = action.payload.verticalScroll;

      if (scrollX !== undefined) {
        state.scrollState.horizontalScroll = scrollX;
      }

      if (scrollY !== undefined) {
        state.scrollState.verticalScroll = scrollY;
      }
    },
    updateIsTouchGestureBeingApplied(state, action: PayloadAction<boolean>) {
      state.isTouchGestureBeingApplied = action.payload;
    },
  },
});

export const {
  updatePosterData,
  setPosterOverlayLoaderVisibility,
  restoreInitialData,
  updateCurrentPageId,
  updatePosterIsVideo,
  updateCurrentTime,
  updateAudioItemIsPlaying,
  updateIsEmbeddedExportMode,
  setSelectedAudioItemUID,
  updateActiveItems,
  updateHoveredItem,
  updateIsPlaying,
  updateHasUndo,
  updateDidPosterRecentlySave,
  updateIsPosterSaving,
  updateDidPosterSaveFailed,
  updateArePosterItemsMoving,
  updateArePosterItemsRotating,
  updateArePosterItemsScaling,
  onPosterItemsModified,
  updateItemData,
  updateHasRedo,
  updateSpellCheckSettingsState,
  updateSidebarState,
  updateCollapsableSidebarState,
  updatePickingMode,
  updatePosterWhiteboardDimensions,
  updatePosterWhiteboardContainerDimensions,
  updateCanvasDimensions,
  updateIsPosterEditorMobileVariant,
  updateVerticalScrollbarState,
  updateHorizontalScrollbarState,
  updateBottomWebSeekbarState,
  updateAiImageState,
  updateAiSubtitleState,
  updateAiVoiceState,
  updateIsUploadingToMyUploads,
  updatePublishDropDownState,
  setInputSuggestions,
  setBusinessAddress,
  updateCopiedStylesState,
  updateAutoSaveTooltipState,
  updateCanvasScroll,
  updateIsTouchGestureBeingApplied,
} = posterEditorSlice.actions;
export const posterEditorReducer = posterEditorSlice.reducer;

export const getActiveItems = createSelector(
  [
    (state: RootState): Record<string, PageObject> | undefined => {
      return state.posterEditor.posterObject?.pages.pagesHashMap;
    },
    (state: RootState): string[] => {
      return state.posterEditor.activeItemIds;
    },
  ],
  (pages, activeItemIds) => {
    if (!pages || activeItemIds.length === 0) {
      return [];
    }

    const activeItems = [];
    for (const [, pageObject] of Object.entries(pages)) {
      for (const activeItemId of activeItemIds) {
        if (pageObject.items.itemsHashMap[activeItemId] !== undefined) {
          activeItems.push(pageObject.items.itemsHashMap[activeItemId]);
        }
      }

      if (activeItems.length === activeItemIds.length) {
        break;
      }
    }

    return activeItems;
  }
);

export const getHoveredItem = createSelector(
  [
    (state: RootState): Record<string, PageObject> | undefined => {
      return state.posterEditor.posterObject?.pages.pagesHashMap;
    },
    (state: RootState): string | undefined => {
      return state.posterEditor.hoveredItemId;
    },
  ],
  (pages, hoveredItemId) => {
    if (!pages || !hoveredItemId) {
      return undefined;
    }

    for (const [, pageObject] of Object.entries(pages)) {
      if (pageObject.items.itemsHashMap[hoveredItemId] !== undefined) {
        return pageObject.items.itemsHashMap[hoveredItemId];
      }
    }

    return undefined;
  }
);

export const getSelectedAudioItem = createSelector(
  [
    (state: RootState): Record<string, AudioItemObject> | undefined => {
      return state.posterEditor.posterObject?.audioClips.audioItemsHashMap;
    },
    (state: RootState): string | undefined => {
      return state.posterEditor.posterObject?.audioClips.selectedAudioItemUID;
    },
  ],
  (audioItemHashMap, selectedAudioItemUID) => {
    if (!audioItemHashMap || !selectedAudioItemUID) {
      return undefined;
    }

    return audioItemHashMap[selectedAudioItemUID];
  }
);

export const getPosterObject = (state: RootState): PosterObject | null => {
  return state.posterEditor.posterObject;
};

export const getCopiedStyles = (state: RootState): CopyableItemStylesAndProperties | undefined => {
  return state.posterEditor.copiedStyles;
};

export const getCurrentPage = createSelector(
  [
    (state: RootState): Record<string, PageObject> | undefined => {
      return state.posterEditor.posterObject?.pages.pagesHashMap;
    },
    (state: RootState): string => {
      return state.posterEditor.currentPageId;
    },
  ],
  (pages, currentPageId) => {
    if (!pages || !currentPageId) {
      return undefined;
    }

    return pages[currentPageId] ?? undefined;
  }
);

export const getCurrentPageOrder = createSelector(
  [
    (state: RootState): PosterPageObject | undefined => {
      return state.posterEditor.posterObject?.pages;
    },
    (state: RootState): string => {
      return state.posterEditor.currentPageId;
    },
  ],
  (pages, currentPageId) => {
    if (!pages || !currentPageId) {
      return undefined;
    }

    const pageOrder = pages.pageOrder.indexOf(currentPageId);
    return pageOrder !== -1 ? pageOrder : undefined;
  }
);

export const getCurrentPageDuration = createSelector(
  [
    (state: RootState): Record<string, PageObject> | undefined => {
      return state.posterEditor.posterObject?.pages.pagesHashMap;
    },
    (state: RootState): string => {
      return state.posterEditor.currentPageId;
    },
  ],
  (pages, currentPageId) => {
    if (!pages || !currentPageId) {
      return undefined;
    }

    return pages[currentPageId].duration ?? undefined;
  }
);

export const getCurrentPageBackground = createSelector(
  [
    (state: RootState): Record<string, PageObject> | undefined => {
      return state.posterEditor.posterObject?.pages.pagesHashMap;
    },
    (state: RootState): string => {
      return state.posterEditor.currentPageId;
    },
  ],
  (pages, currentPageId) => {
    if (!pages || !currentPageId) {
      return undefined;
    }

    return pages[currentPageId].background ?? undefined;
  }
);

export const getNumberOfPages = (state: RootState): number => {
  if (!state.posterEditor.posterObject?.pages.pagesHashMap) {
    return 0;
  }
  return Object.keys(state.posterEditor.posterObject.pages.pagesHashMap).length;
};

export const isScaleAnimatingToFitToScreen = createSelector(
  [
    (state: RootState): boolean => {
      return state.posterEditor.posterObject?.scaling.isScaleAnimating === true;
    },
    (state: RootState): boolean => {
      return state.posterEditor.posterObject?.scaling.isCurrentScaleBestFit === true;
    },
  ],
  (isScaleAnimating, isCurrentScaleBestFit) => {
    return isScaleAnimating && isCurrentScaleBestFit;
  }
);

export const getNumberOfItemsInCurrentPage = createSelector(
  [
    (state: RootState): Record<string, PageObject> | undefined => {
      return state.posterEditor.posterObject?.pages.pagesHashMap;
    },
    (state: RootState): string => {
      return state.posterEditor.currentPageId;
    },
  ],
  (pages, currentPageId) => {
    if (!pages || !currentPageId) {
      return 0;
    }

    return Object.keys(pages[currentPageId].items.itemsHashMap).length;
  }
);

export const getAllTranscriptItemObjects = createSelector(
  [
    (state: RootState): Record<string, PageObject> | undefined => {
      return state.posterEditor.posterObject?.pages.pagesHashMap;
    },
    (state: RootState): string => {
      return state.posterEditor.currentPageId;
    },
  ],
  (pages, currentPageId) => {
    if (!pages || !currentPageId) {
      return {};
    }
    const transcriptItemObjectsHashmap: Record<string, TranscriptItemObject> = {};
    Object.entries(pages[currentPageId].items.itemsHashMap).forEach(([key, itemObj]) => {
      if (itemObj.gitype === ITEM_TYPE.TRANSCRIPT) {
        transcriptItemObjectsHashmap[key] = itemObj as TranscriptItemObject;
      }
    });

    return transcriptItemObjectsHashmap;
  }
);

/**
 * returns item object if it is the only item selected otherwise returns null
 */
export const getSingleActiveItemObject = (state: RootState): ItemObject | null => {
  const items = getActiveItems(state);
  if (!items || items.length === 0 || items.length > 1) {
    return null;
  }

  return items[0];
};

export const getSingleActiveItemObjectId = (state: RootState): string | null => {
  const items = getActiveItems(state);
  if (!items || items.length === 0 || items.length > 1) {
    return null;
  }

  return items[0].uid;
};

export const getNumberOfActiveItems = (state: RootState): number => {
  const items = getActiveItems(state);
  return items.length;
};

export const getSingleActiveItem = (state: RootState): ItemObject | null => {
  const items = getActiveItems(state);
  if (!items || items.length === 0 || items.length > 1) {
    return null;
  }

  return items?.[0] ?? null;
};

export const getCurrentSubtitle = (state: RootState): SubtitleObject | undefined => {
  const activeItem = getSingleActiveItemObject(state);
  if (!activeItem || activeItem.gitype !== ITEM_TYPE.TRANSCRIPT) {
    return undefined;
  }

  const transcriptItem = activeItem as TranscriptItemObject;
  const {currentTime} = state.posterEditor;

  for (const [, subtitle] of Object.entries(transcriptItem.subtitlesHashmap)) {
    if (subtitle.startTime <= currentTime && subtitle.endTime >= currentTime) {
      return subtitle;
    }
  }

  return undefined;
};

export const getVideoItemsWithAudioInOrder = createSelector(
  [
    (state: RootState): Record<string, PageObject> | undefined => {
      return state.posterEditor.posterObject?.pages.pagesHashMap;
    },
    (state: RootState): string => {
      return state.posterEditor.currentPageId;
    },
  ],
  (pages, currentPageId): VideoItemObject[] => {
    if (!pages || !currentPageId) {
      return [];
    }

    const itemsIdsInOrder = pages[currentPageId].items.itemsOrder;
    const videoItems: VideoItemObject[] = [];

    for (const itemId of itemsIdsInOrder) {
      const item = pages[currentPageId].items.itemsHashMap[itemId];
      if (item.gitype === ITEM_TYPE.VIDEO) {
        videoItems.push(item as VideoItemObject);
      }
    }

    return videoItems;
  }
);

// returns audio items in order of their onPosterStartTime (using name as a tie-breaker)
export const getAudioItemsInOrder = createSelector(
  [
    (state: RootState): AudioClipsObject | undefined => {
      return state.posterEditor.posterObject?.audioClips;
    },
  ],
  (audioClipsObject): AudioItemObject[] => {
    const audioItems: AudioItemObject[] = [];
    if (audioClipsObject) {
      audioItems.push(...Object.values(audioClipsObject.audioItemsHashMap));
    }

    audioItems.sort((a, b) => {
      if (a.onPosterStartTime !== b.onPosterStartTime) {
        return a.onPosterStartTime - b.onPosterStartTime;
      }
      return a.name.localeCompare(b.name);
    });

    return audioItems;
  }
);

export const getTextItems = createSelector(
  [
    (state: RootState): PosterPageObject | undefined => {
      return state.posterEditor.posterObject?.pages;
    },
  ],
  (pages): (TextItemObject | FancyTextItemObject)[] => {
    if (!pages) return [];

    return Object.values(pages.pagesHashMap).flatMap((page) => {
      const items = Object.values(page.items.itemsHashMap);

      return items.filter((item): item is TextItemObject | FancyTextItemObject => {
        return item.gitype === ITEM_TYPE.TEXT || item.gitype === ITEM_TYPE.FANCY_TEXT;
      });
    });
  }
);

export const getActivityIndicatorTypeForAi = createSelector(
  [
    (state: RootState): AiState => {
      return state.posterEditor.aiState;
    },
  ],
  (aiState): ActivityIndicatorType | undefined => {
    if (aiState.aiImage === AiImageState.GENERATING || aiState.aiSubtitles === AiSubtitleState.GENERATING) {
      return ActivityIndicatorType.LOADING;
    }
    if (aiState.aiSubtitles === AiSubtitleState.READY_FAILED) {
      return ActivityIndicatorType.ERROR;
    }
    if (aiState.aiImage === AiImageState.READY || aiState.aiSubtitles === AiSubtitleState.READY_SUCCESS) {
      return ActivityIndicatorType.SUCCESS;
    }
    return undefined;
  }
);

export const getActivityIndicatorTypeForAiImage = createSelector(
  [
    (state: RootState): AiImageState => {
      return state.posterEditor.aiState.aiImage;
    },
  ],
  (aiImageStatus): ActivityIndicatorType | undefined => {
    if (aiImageStatus === AiImageState.GENERATING) {
      return ActivityIndicatorType.LOADING;
    }
    if (aiImageStatus === AiImageState.READY) {
      return ActivityIndicatorType.SUCCESS;
    }
    return undefined;
  }
);

export const getActivityIndicatorTypeForAiSubtitle = createSelector(
  [
    (state: RootState): AiSubtitleState => {
      return state.posterEditor.aiState.aiSubtitles;
    },
  ],
  (aiSubtitleStatus): ActivityIndicatorType | undefined => {
    if (aiSubtitleStatus === AiSubtitleState.GENERATING) {
      return ActivityIndicatorType.LOADING;
    }
    if (aiSubtitleStatus === AiSubtitleState.READY_SUCCESS) {
      return ActivityIndicatorType.SUCCESS;
    }
    if (aiSubtitleStatus === AiSubtitleState.READY_FAILED) {
      return ActivityIndicatorType.ERROR;
    }
    return undefined;
  }
);

export const getActivityIndicatorTypeForAiVoice = createSelector(
  [
    (state: RootState): AiVoiceState => {
      return state.posterEditor.aiState.aiVoice;
    },
  ],
  (aiVoiceStatus): ActivityIndicatorType | undefined => {
    if (aiVoiceStatus === AiVoiceState.GENERATING) {
      return ActivityIndicatorType.LOADING;
    }
    if (aiVoiceStatus === AiVoiceState.READY) {
      return ActivityIndicatorType.SUCCESS;
    }
    if (aiVoiceStatus === AiVoiceState.ERROR) {
      return ActivityIndicatorType.ERROR;
    }
    return undefined;
  }
);
